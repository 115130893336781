import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { shallowEqual } from "react-redux";

import { API, ROUTER } from "../constants/env";
import Swal from "sweetalert2";
import moment from "moment";
import {
    setEmployeesPlanningData,
    setPlanningInfo,
    updatePlanning,
    updatePlanningOptions,
    setPlanningInfoNote,
    resetPlanningSlice,
    setValidConges,
} from "../redux/features/planning/planningSlice";

import {
    managePlans,
    optimizedManagePlans,
    getDatesInRange,
    getDatesIndexes,
} from "../_helpers/Functions";

export const usePlanning = () => {
    const dispatch = useAppDispatch();
    const {
        week,
        year,
        departments,
        departmentsServices,
        daysServices,
        draggedShiftType,
        plannedWeeks,
        options,
        loading,
        startOfWeek,
        endOfWeek,
        selectedDepartment,
        planningInfoDepartments,
        planningInfo,
        employeesPlanningData,
        sortedEmployeesWithOrder,
        services,
        fixedPlans,
        disponibility,
        hoursServices,
        departmentsHoursServices,
        steakers,
        absenceColors,
        loadingVerification,
        toSearchPlanningEmployees,
        siteSettingsEtab,
        customSort,
        validCongesByUser,
        // resetAllPlanning,
    } = useAppSelector((state: any) => state.planning, shallowEqual);
    const resetPlanning = useCallback(() => {
        dispatch(resetPlanningSlice());
    }, [dispatch]);
    const getPlanningInfo = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;

            try {
                const response = await fetch(
                    API.client.planning.getPlanningInfo,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            licenseId,
                            siteId,
                            week,
                            year,
                        }),
                    },
                );
                if (response.ok === true) {
                    const data = await response.json();
                    dispatch(setPlanningInfo(data));
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            dispatch(setPlanningInfo(null));
        }
    };
    const getPlanningInfoDepartments = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            let planningInfoDepartments: any = {};
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;
            const fetchPromises = departments.map(async (department: any) => {
                try {
                    const response = await fetch(
                        API.client.planning.getPlanningInfo,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                            body: JSON.stringify({
                                departmentId: department._id,
                                licenseId,
                                siteId,
                                week,
                                year,
                            }),
                        },
                    );

                    if (response.ok) {
                        const data = await response.json();
                        planningInfoDepartments[department?._id] = data;
                    }
                } catch (err) {
                    console.log(err);
                }
            });
            await Promise.all(fetchPromises);
            dispatch(updatePlanning({ planningInfoDepartments }));
        }
    };
    const getWeeklyPlanning = async (depId?: string) => {
        dispatch(updatePlanning({ loading: true }));
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;
            let map: any = {};
            if (depId) {
                try {
                    const response = await fetch(
                        API.client.planning.weekPlans,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem(
                                    "token",
                                )}`,
                            },
                            body: JSON.stringify({
                                department: depId,
                                licenseId,
                                siteId,
                                week,
                                year,
                                startOfWeek,
                                endOfWeek,
                            }),
                        },
                    );
                    if (response.ok === true) {
                        const data = await response.json();

                        if (data.length === 0) {
                            dispatch(setEmployeesPlanningData({ reset: {} }));
                        } else {
                            let managedData = managePlans(data);

                            managedData?.employees?.map((item: any) => {
                                const { employeeId, employeePlansPerDay } =
                                    item;

                                map[employeeId] = employeePlansPerDay;
                            });
                            managedData.employees = map;

                            dispatch(
                                setEmployeesPlanningData({
                                    reset: managedData,
                                }),
                            );
                        }
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    // Dispatch loading to false after all requests
                    dispatch(updatePlanning({ loading: false }));
                }
                return;
            }
            // for (let department of departments) {
            // const department = selectedDepartment?._id || null;
            let planningData: any = {};
            const fetchPromises = [...departments, { _id: null }].map(
                async (department: any) => {
                    try {
                        const response = await fetch(
                            API.client.planning.weekPlans,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bearer ${localStorage.getItem(
                                        "token",
                                    )}`,
                                },
                                body: JSON.stringify({
                                    department: department._id,
                                    licenseId,
                                    siteId,
                                    week,
                                    year,
                                    startOfWeek,
                                    endOfWeek,
                                }),
                            },
                        );

                        if (response.ok) {
                            const data = await response.json();
                            let managedData = managePlans(data);
                            let newMap: any = { ...planningData.employees };
                            managedData?.employees?.map((item: any) => {
                                const { employeeId, employeePlansPerDay } =
                                    item;
                                newMap[employeeId] = employeePlansPerDay;
                            });
                            planningData.employees = { ...newMap };
                        }
                    } catch (err) {
                        console.log(err);
                    }
                },
            );
            await Promise.all(fetchPromises);
            dispatch(
                setEmployeesPlanningData({
                    reset: planningData,
                }),
            );
        }
        dispatch(updatePlanning({ loading: false }));
    };
    // console.log(
    //     "optimizedManagePlans *************** = ",
    //     optimizedManagePlans(data),
    // );
    const setPlanningProperties = ({
        week,
        year,
        startOfWeek,
        endOfWeek,
    }: any) => {
        dispatch(updatePlanning({ week, year, startOfWeek, endOfWeek }));
    };
    const getPlanningServices = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            try {
                const response = await fetch(
                    API.client.settings.schedule.getServices(siteId),
                    {
                        method: "GET",
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                            "Content-Type": "application/json",
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    let copyData = data;
                    for (let i = 0; i < copyData.length; i++) {
                        copyData[i].value = [];
                    }
                    dispatch(updatePlanning({ services: copyData }));
                }
            } catch (err) {
                console.error(err);
            }
        }
    };
    const getFixedPlans = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;
            const department = selectedDepartment?._id || null;
            try {
                const response = await fetch(
                    API.client.planning.getFixedPlans,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            department,
                            licenseId,
                            siteId,
                        }),
                    },
                );
                if (response.ok === true) {
                    const data = await response.json();
                    dispatch(updatePlanning({ fixedPlans: data }));
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const addWeekPlanningNotes = async (noteItem: any, department?: any) => {
        // let noteItemCopy = { ...noteItem, note: "" };
        // for (let i = 0; i < noteItem.note?.length; i++) {
        const containsNonSpaces = /\S/.test(noteItem.note);
        if (!containsNonSpaces) {
            // noteItemCopy.note=noteItem.note);
            return;
        }
        // }
        if (noteItem.day) {
            const selectedBranch = sessionStorage.getItem("selectedBranch");
            if (selectedBranch) {
                const parsedBranch = JSON.parse(selectedBranch);
                const licenseId = parsedBranch.license;
                const siteId = parsedBranch._id;
                let note: any = [];
                let planningId = undefined;
                if (department) {
                    planningId = planningInfoDepartments[department]?._id;
                    note = planningInfoDepartments[department]?.note || [];
                } else {
                    planningId = planningInfo?._id || undefined;
                    note = planningInfo?.note || [];
                }

                const notes = [...note, noteItem];
                try {
                    const response = await fetch(API.client.planning.addNote, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            planningId,
                            note: notes,
                            licenseId,
                            siteId,
                            week,
                            year,
                        }),
                    });
                    if (response.ok === true) {
                        const data = await response.json();
                        dispatch(
                            setPlanningInfoNote({
                                note: data.note,
                                department,
                            }),
                        );
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    const editWeekPlanningNotes = async (noteItem: any, department?: any) => {
        if (noteItem.day && noteItem.note) {
            const selectedBranch = sessionStorage.getItem("selectedBranch");
            if (selectedBranch) {
                const parsedBranch = JSON.parse(selectedBranch);
                const licenseId = parsedBranch.license;
                const siteId = parsedBranch._id;
                const { week, year } = planningInfo;
                let note: any = [];
                let planningId = undefined;
                if (department) {
                    planningId = planningInfoDepartments[department]?._id;
                    note = planningInfoDepartments[department]?.note || [];
                } else {
                    planningId = planningInfo?._id || undefined;
                    note = planningInfo?.note || [];
                }
                let notes: any = [];
                let onlyone = 0;
                for (let i = 0; i < note.length; i++) {
                    if (note[i].day === noteItem.day && onlyone === 0) {
                        onlyone++;
                        const containsNonSpaces = /\S/.test(noteItem.note);
                        if (containsNonSpaces) {
                            notes.push(noteItem);
                        }
                    } else {
                        notes.push(note[i]);
                    }
                }
                try {
                    const response = await fetch(API.client.planning.addNote, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            planningId,
                            note: notes,
                            licenseId,
                            siteId,
                            week,
                            year,
                        }),
                    });
                    if (response.ok === true) {
                        const data = await response.json();
                        dispatch(
                            setPlanningInfoNote({
                                note: data.note,
                                department,
                            }),
                        );
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    const deleteWeekPlanningNote = async (dayNumber: any, department?: any) => {
        const noteItemDay = moment(startOfWeek)
            .add(dayNumber, "days")
            .format("DD/MM/YYYY");
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;
            const { week, year } = planningInfo;
            let note: any = [];
            let planningId = undefined;
            if (department) {
                planningId = planningInfoDepartments[department]?._id;
                note = planningInfoDepartments[department]?.note || [];
            } else {
                planningId = planningInfo?._id || undefined;
                note = planningInfo?.note || [];
            }
            let notes: any = [];
            for (let i = 0; i < note.length; i++) {
                if (note[i].day !== noteItemDay) {
                    notes.push(note[i]);
                }
            }
            try {
                const response = await fetch(API.client.planning.addNote, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                    body: JSON.stringify({
                        planningId,
                        note: notes,
                        licenseId,
                        siteId,
                        week,
                        year,
                    }),
                });
                if (response.ok === true) {
                    const data = await response.json();
                    dispatch(
                        setPlanningInfoNote({ note: data.note, department }),
                    );
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const printPlanning = React.useCallback(
        async (depId?: any) => {
            const selectedBranch = sessionStorage.getItem("selectedBranch");
            if (selectedBranch) {
                const parsedBranch = JSON.parse(selectedBranch);
                const licenseId = parsedBranch.license;
                const siteId = parsedBranch._id;
                const { week, year } = planningInfo;

                const currentDate = moment(startOfWeek, "YYYY-MM-DD").format(
                    "DD/MM/YYYY",
                );

                let department = depId;
                if (!depId) {
                    department = selectedDepartment?._id || null;
                }
                try {
                    const response = await fetch(API.client.planning.print, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            currentDate,
                            department,
                            startOfWeek,
                            endOfWeek,
                            licenseId,
                            siteId,
                            week,
                            year,
                        }),
                    });
                    if (response.ok) {
                        const data = await response.json();
                        const pdfLink = data.link;
                        window.open(ROUTER.STATIC_FILE2(pdfLink), "_blank");
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [planningInfo, selectedDepartment, startOfWeek, endOfWeek],
    );
    const verifyConvention = async (departementId: any) => {
        const planningId = departementId
            ? planningInfoDepartments[departementId]?._id
            : planningInfo?._id;
        try {
            dispatch(updatePlanning({ loadingVerification: true }));
            const response = await fetch(API.client.planning.verifyConvention, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    planningId,
                    departementId,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                dispatch(updatePlanning({ loadingVerification: false }));
                return data;
                // if (planningId && depId) {
                //     dispatch(
                //         updatePlanning({
                //             planningInfoDepartment: { data, depId },
                //         }),
                //     );
                // } else {
                //     dispatch(setPlanningInfo(data));
                // }
            }
        } catch (err) {
            dispatch(updatePlanning({ loadingVerification: false }));
            console.log(err);

            return ["error"];
        }
        dispatch(updatePlanning({ loadingVerification: false }));

        return ["error"];
    };
    const publishPlanning = async (yes = true, depId?: string) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;
            const planningId = depId
                ? planningInfoDepartments[depId]?._id
                : planningInfo?._id;
            try {
                const response = await fetch(API.client.planning.publish, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                    body: JSON.stringify({
                        licenseId,
                        planningId,
                        publish: yes,
                    }),
                });
                if (response.ok) {
                    const data = await response.json();
                    if (planningId && depId) {
                        dispatch(
                            updatePlanning({
                                planningInfoDepartment: { data, depId },
                            }),
                        );
                    } else {
                        dispatch(setPlanningInfo(data));
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const getCustomSort = async () => {
        // const selectedBranch = sessionStorage.getItem("selectedBranch");
        // if (selectedBranch) {
        //     const parsedBranch = JSON.parse(selectedBranch);
        //     const siteId = parsedBranch._id;
        //     let customSort: any = {};
        //     for (let i = 0; i < planning.departments.length; i++) {
        //         try {
        //             const response = await fetch(
        //                 API.client.planning.customSort(
        //                     siteId,
        //                     planning.departments[i]._id,
        //                 ),
        //                 {
        //                     method: "GET",
        //                     headers: {
        //                         "Content-Type": "application/json",
        //                         authorization: `Bearer ${localStorage.getItem(
        //                             "token",
        //                         )}`,
        //                     },
        //                 },
        //             );
        //             if (response.ok) {
        //                 const data = await response.json();
        //                 customSort[planning.departments[i]._id] = data;
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     }
        //     dispatch(updatePlanning({ customSort }));
        // }
    };
    const getEmployeesCustomSort = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            let employeesCustomSort: any = {};
            try {
                const response = await fetch(
                    API.client.planning.customSortEmployees(siteId),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    for (let sortedDepartment of data) {
                        employeesCustomSort[
                            sortedDepartment.department === null
                                ? "withoutDepartment"
                                : sortedDepartment.department
                        ] = {
                            ...sortedDepartment,
                        };
                    }

                    dispatch(
                        updatePlanning({
                            customSort: employeesCustomSort,
                        }),
                    );
                }
            } catch (err) {
                console.log(err);
            }

            // dispatch(updatePlanning({ customSort: employeesCustomSort }));
        }
    };
    const getDisponibility = async () => {
        try {
            const response = await fetch(API.client.planning.disponibility, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    startDate: startOfWeek,
                    endDate: endOfWeek,
                    // populate: true,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                let managedDispo: any = {};
                data.map((item: any, index: number) => {
                    const { employee, startDate, endDate } = item;
                    let start = moment(startDate);
                    let end = moment(endDate);
                    while (start.isSameOrBefore(end)) {
                        managedDispo[employee] = {
                            ...managedDispo[employee],
                            [start.format("YYYY-MM-DD")]: { ...item },
                        };
                        start.add(1, "days");
                    }
                    // console.log("data ==== =  ", managedDispo[employee]);
                });

                dispatch(updatePlanning({ disponibility: managedDispo }));
            }
        } catch (err) {
            console.log(err);
        }
        // }
        // }
    };
    const addnewShift = async (data: any) => {
        const {
            employeeId,
            from,
            to,
            pause,
            type,
            typeAbsence,
            comment,
            days,
            steaker,
            repas,
            secondStart,
            secondEnd,
            department,
        } = data;
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            const licenseId = parsedBranch.license;
            const planningId =
                (department
                    ? planningInfoDepartments[department]?._id
                    : planningInfo?._id) || undefined;

            let objectToSend: any = {
                department,
                planId: null,
                licenseId,
                siteId,
                planningId,
                employeeId,
                from,
                to,
                pause,
                type,
                week,
                year,
                days,
                repas,
                typeAbsence: type === 1 ? null : typeAbsence,
            };
            if (steaker !== "") {
                objectToSend.steaker = steaker;
            }
            if (/\S/.test(comment) && comment && comment !== "") {
                objectToSend.comment = comment;
            } else {
                objectToSend.comment = null;
            }
            if (secondStart !== "") {
                objectToSend.secondStart = secondStart;
            }
            if (secondEnd !== "") {
                objectToSend.secondEnd = secondEnd;
            }
            try {
                const response = await fetch(API.client.planning.addShift, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                    body: JSON.stringify(objectToSend),
                });
                let data = await response.json();
                if (data.error && data.message) {
                    Swal.fire({
                        title: "Attention!",
                        text: data.message,
                        icon: "error",
                        timer: 3000,
                    });
                    return false;
                }

                // let error = false;
                data?.map((item: any) => {
                    if (item.conflict) {
                        // error = true;
                        let textMessage = "";
                        if (item.fromAnotherSite) {
                            textMessage =
                                "Le créneau horaire choisi est déjà planifié dans un autre établissement.";
                        } else {
                            textMessage =
                                "Le créneau horaire choisi est déjà planifié";
                        }
                        Swal.fire({
                            title: "Attention!",
                            text: textMessage,
                            icon: "error",
                            timer: 3000,
                        });
                    }
                });
                // if (error) return false;
                data = [...data?.filter((item: any) => !item.conflict)];

                if (data?.[0]?.employee) {
                    handleAddShiftToData(data, data?.[0]?.employee);
                    return true;
                }

                return false;
            } catch (err: any) {
                return false;
            }
        } else {
            Swal.fire({
                title: "Attention!",
                text: "Pas d'établissements sélectionné",
                icon: "error",
                timer: 3000,
            });
            return false;
        }
    };
    const handleAddShiftToData = (shiftData: any, empId: string) => {
        let copyData: any = [];
        if (employeesPlanningData.employees?.[empId]?.length > 0) {
            copyData = [...employeesPlanningData.employees[empId]];
        }
        for (let i = 0; i < shiftData?.length; i++) {
            let found = false;
            let date = moment(shiftData[i].day).format("YYYY-MM-DD");

            copyData = [
                ...copyData?.map((item: any) => {
                    if (item.day === date) {
                        found = true;
                        return {
                            ...item,
                            workShares: [
                                ...item.workShares,
                                { ...shiftData[i], day: date },
                            ],
                        };
                    }
                    return item;
                }),
            ];
            if (!found) {
                copyData = [
                    ...copyData,
                    {
                        day: date,
                        workShares: [{ ...shiftData[i], day: date }],
                    },
                ];

                copyData = copyData.sort((a: any, b: any) => {
                    if (a.day < b.day) return -1;
                    if (a.day > b.day) return 1;
                    return 0;
                });
            }
        }
        // let sortedData = [
        //     ...copyData?.map((data: any, index: number) => {
        //         let workShares1 = [
        //             ...data.workShares.sort(
        //                 (a: any, b: any) => a.from - b.from,
        //             ),
        //         ];
        //         return {
        //             ...data,
        //             workShares: workShares1,
        //         };
        //     }),
        // ];
        // console.log(" sortedData = ", sortedData);

        let updatedEmployees = {
            ...employeesPlanningData.employees,
        };
        updatedEmployees[empId] = copyData;

        dispatch(
            setEmployeesPlanningData({
                reset: {
                    ...employeesPlanningData,
                    employees: updatedEmployees,
                },
            }),
        );
    };
    const getSteakers = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            try {
                const response = await fetch(
                    API.client.planning.steakers(siteId),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok === true) {
                    let data = await response.json();
                    data.sort((a: any, b: any) => {
                        const nameA = a.name.toLowerCase();
                        const nameB = b.name.toLowerCase();
                        return nameA.localeCompare(nameB, "en", {
                            sensitivity: "base",
                        });
                    });
                    dispatch(updatePlanning({ steakers: data }));
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            dispatch(updatePlanning({ steakers: null }));
        }
    };
    const addNewSteakers = async (newSteaker: any) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            try {
                const response = await fetch(
                    API.client.planning.createSteaker,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            siteId,
                            ...newSteaker,
                        }),
                    },
                );
                if (response.ok === true) {
                    let data = await response.json();
                    dispatch(
                        updatePlanning({
                            steakers: [...steakers, data],
                        }),
                    );
                    return true;
                }
            } catch (err) {
                console.log(err);
                return false;
            }
            return false;
        }
        return false;
    };
    const getAbsenceColors = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            try {
                const response = await fetch(
                    API.client.planning.absenceColor(siteId),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok === true) {
                    let data = await response.json();
                    dispatch(updatePlanning({ absenceColors: data }));
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            dispatch(updatePlanning({ absenceColors: null }));
        }
    };

    const deletePlanning = async (depId?: string) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        const planningId = depId
            ? planningInfoDepartments[depId]?._id
            : planningInfo?._id;
        if (selectedBranch && planningId !== undefined) {
            const licenseId = JSON.parse(selectedBranch).license;
            try {
                const response = await fetch(
                    API.client.planning.deletePlanning,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            licenseId,
                            planningId,
                        }),
                    },
                );
                if (response.ok) {
                    getWeeklyPlanning(depId);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const addNewFixedShift = async ({
        from,
        to,
        pause,
        type,
        repas,
        typeAbsence,
        steaker,
        comment,
    }: any) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            const licenseId = parsedBranch.license;
            const planningId = planningInfo?._id || undefined;
            const department = selectedDepartment?._id || null;
            let objectToSend: any = {
                department,
                planId: null,
                licenseId,
                siteId,
                planningId,
                from,
                to,
                pause,
                type,
                repas,
                typeAbsence: type === 1 ? null : typeAbsence,
            };
            if (steaker !== "") {
                objectToSend.steaker = steaker;
            }
            if (comment !== "") {
                objectToSend.comment = comment;
            }
            try {
                const response = await fetch(
                    API.client.planning.fixedPlan.create,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify(objectToSend),
                    },
                );
                const data = await response.json();
                if (response.ok) {
                    dispatch(
                        updatePlanning({
                            fixedPlans: [...fixedPlans, data],
                        }),
                    );
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const deleteFixedShift = async (shiftId: string) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const licenseId = JSON.parse(selectedBranch).license;
            try {
                const response = await fetch(
                    API.client.planning.fixedPlan.delete,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            shiftId,
                            licenseId,
                        }),
                    },
                );
                const data = await response.json();
                if (response.ok) {
                    if (response.ok) {
                        let theFixedPlans: any = [];
                        for (let i = 0; i < fixedPlans?.length; i++) {
                            if (fixedPlans[i]._id !== data._id) {
                                theFixedPlans.push(fixedPlans[i]);
                            }
                        }
                        dispatch(
                            updatePlanning({
                                fixedPlans: theFixedPlans,
                            }),
                        );
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const editFixedShift = async ({
        from,
        to,
        pause,
        type,
        repas,
        typeAbsence,
        steaker,
        comment,
        planId,
        department,
    }: any) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            const licenseId = parsedBranch.license;
            const planningId = planningInfo?._id || undefined;
            let objectToSend: any = {
                department,
                planId,
                licenseId,
                siteId,
                planningId,
                from,
                to,
                pause,
                type,
                repas,
                typeAbsence: type === 1 ? null : typeAbsence,
            };
            if (steaker !== "") {
                objectToSend.steaker = steaker;
            }
            if (comment !== "") {
                objectToSend.comment = comment;
            }
            try {
                const response = await fetch(
                    API.client.planning.fixedPlan.update,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify(objectToSend),
                    },
                );
                const data = await response.json();
                if (response.ok) {
                    let theFixedPlans: any = [];
                    for (let i = 0; i < fixedPlans?.length; i++) {
                        if (fixedPlans[i]._id === data._id) {
                            theFixedPlans.push(data);
                        } else {
                            theFixedPlans.push(fixedPlans[i]);
                        }
                    }
                    dispatch(
                        updatePlanning({
                            fixedPlans: theFixedPlans,
                        }),
                    );
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const deleteShift = async (
        shiftId: string,
        getShifts?: boolean,
        keepLoading?: boolean,
    ) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const licenseId = JSON.parse(selectedBranch).license;
            try {
                // dispatch(updatePlanning({ loading: true }));

                const response = await fetch(
                    API.client.planning.fixedPlan.delete,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            shiftId,
                            licenseId,
                        }),
                    },
                );
                const data = await response.json();
                if (response.ok) {
                    // if (!keepLoading)
                    //     dispatch(updatePlanning({ loading: false }));
                    // if (getShifts) getWeeklyPlanning(true);
                    handleDeleteShiftFromData(shiftId);
                }
            } catch (err) {
                console.log(err);
                // dispatch(updatePlanning({ loading: false }));
            }
        }
    };
    const handleDeleteShiftFromData = (shiftId: string) => {
        let copyData: Record<string, any> = {
            ...employeesPlanningData.employees,
        };
        let changed = false;
        for (const [id, data] of Object.entries(copyData)) {
            // Filter out the shift with the given ID
            changed = false;
            const updatedShifts = data.map((item: any) => {
                const newWorkShares = item.workShares.filter((shift: any) => {
                    if (shift._id !== shiftId) changed = true;
                    return shift._id !== shiftId;
                });
                if (newWorkShares.length === 0) return null;
                return { day: item.day, workShares: newWorkShares };
            });

            copyData[id] = updatedShifts.filter((item: any) => item !== null);
            if (copyData[id].length === 0) {
                delete copyData[id];
            } else if (changed) {
                copyData[id] = [
                    ...copyData[id]?.map((data: any) => {
                        return {
                            ...data,
                            workShares: [
                                ...data.workShares.sort(
                                    (a: any, b: any) => a.from - b.from,
                                ),
                            ],
                        };
                    }),
                ];
            }
        }

        dispatch(
            setEmployeesPlanningData({
                reset: {
                    ...employeesPlanningData,
                    employees: copyData,
                },
            }),
        );
    };

    const editShift = async (shiftData: any) => {
        const {
            employeeId,
            from,
            to,
            pause,
            type,
            repas,
            typeAbsence,
            steaker,
            comment,
            planId,
            day,
            days,
            department,
        } = shiftData;
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            const licenseId = parsedBranch.license;
            const planningId = planningInfo?._id || undefined;

            let objectToSend: any = {
                department,
                employeeId,
                planId,
                licenseId,
                siteId,
                planningId,
                from,
                to,
                pause,
                day,
                week,
                year,
                days,
                repas,
                type,
                typeAbsence: type === 1 ? null : typeAbsence,
            };
            if (steaker !== "") {
                objectToSend.steaker = steaker;
            }
            if (/\S/.test(comment) && comment && comment !== "") {
                objectToSend.comment = comment;
            } else {
                objectToSend.comment = null;
            }
            try {
                const response = await fetch(API.client.planning.shift.update, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                    body: JSON.stringify(objectToSend),
                });
                const data = await response.json();
                if (response.ok) {
                    handleEditShiftToData(data);
                    return true;
                } else {
                    let error = false;
                    if (data.conflict) {
                        error = true;
                        let textMessage = "";
                        if (data.fromAnotherSite) {
                            textMessage =
                                "Le créneau horaire choisi est déjà planifié dans un autre établissement.";
                        } else {
                            textMessage =
                                "Le créneau horaire choisi est déjà planifié";
                        }
                        Swal.fire({
                            title: "Attention!",
                            text: textMessage,
                            icon: "error",
                            timer: 3000,
                        });
                    }
                    if (error) return false;
                }
                if (data.error) {
                    Swal.fire({
                        title: "Attention!",
                        text: data.message,
                        icon: "error",
                        timer: 3000,
                    });
                }
                return false;
            } catch (err: any) {
                console.log(err);
                Swal.fire({
                    title: "Attention!",
                    text: err.error,
                    icon: "error",
                    timer: 3000,
                });
                return false;
            }
        } else {
            Swal.fire({
                title: "Attention!",
                text: "Pas d'établissements sélectionné",
                icon: "error",
                timer: 3000,
            });
            return false;
        }
    };

    const handleEditShiftToData = React.useCallback(
        (newData: any) => {
            const newEmployeeId = newData.employee;
            const newDay = moment(newData.day).format("YYYY-MM-DD");
            let updatedEmployees: Record<string, any[]> = {
                ...employeesPlanningData.employees,
            };
            for (const [id, data] of Object.entries(updatedEmployees)) {
                // Filter out the shift with the given ID
                const updatedShifts = data.map((item: any) => {
                    const newWorkShares = item.workShares.filter(
                        (shift: any) => shift._id !== newData._id,
                    );
                    if (newWorkShares.length === 0) return null;
                    return { day: item.day, workShares: newWorkShares };
                });
                updatedEmployees[id] = updatedShifts.filter(
                    (item: any) => item !== null,
                );
                if (updatedEmployees[id].length === 0) {
                    delete updatedEmployees[id];
                }
            }
            if (!Array.isArray(updatedEmployees[newEmployeeId])) {
                updatedEmployees[newEmployeeId] = [];
            }
            let copyData2 = [...updatedEmployees[newEmployeeId]];
            let found = false;
            copyData2 = copyData2?.map((item: any) => {
                if (item.day === newDay) {
                    found = true;
                    let workShares = [
                        ...item.workShares,
                        { ...newData, day: newDay },
                    ];
                    return {
                        ...item,
                        workShares,
                    };
                }
                return item;
            });
            if (!found) {
                copyData2 = [
                    ...copyData2,
                    {
                        day: newDay,
                        workShares: [{ ...newData, day: newDay }],
                    },
                ];
                copyData2.sort((a: any, b: any) => {
                    if (a.day < b.day) return -1;
                    if (a.day > b.day) return 1;
                    return 0;
                });
            }
            let sortedData = copyData2?.map((data: any) => {
                return {
                    ...data,
                    workShares: [
                        ...data.workShares.sort(
                            (a: any, b: any) => a.from - b.from,
                        ),
                    ],
                };
            });
            updatedEmployees[newEmployeeId] = sortedData;
            dispatch(
                setEmployeesPlanningData({
                    reset: {
                        ...employeesPlanningData,
                        employees: updatedEmployees,
                    },
                }),
            );
        },
        [dispatch, employeesPlanningData],
    );
    const copyShift = React.useCallback(
        async (data: any, copyType: string) => {
            const { shiftId, employeeId, day, department } = data;
            const selectedBranch = sessionStorage.getItem("selectedBranch");
            if (selectedBranch) {
                console.log(" selectedBranch = ", selectedBranch);
                const parsedBranch = JSON.parse(selectedBranch);
                const siteId = parsedBranch._id;
                const licenseId = parsedBranch.license;
                //@MALEK: I don't know why you always apply the planning of dept=null when move or copy a shift
                // this is wrong and it is better I guess that you never use it
                // const planningId = planningInfo?._id || undefined;
                try {
                    const response = await fetch(
                        API.client.planning.shift.copy,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem(
                                    "token",
                                )}`,
                            },
                            body: JSON.stringify({
                                day,
                                department,
                                licenseId,
                                plan: shiftId,
                                // planningId,
                                siteId,
                                type: copyType,
                                userId: employeeId,
                            }),
                        },
                    );
                    const data = await response.json();
                    if (data.error) {
                        Swal.fire({
                            title: "Attention!",
                            text: data.message,
                            icon: "error",
                            timer: 3000,
                        });
                        return false;
                    }
                    if (response.ok) {
                        let error = false;
                        if (data[0].conflict) {
                            error = true;
                            let textMessage = "";
                            if (data[0].fromAnotherSite) {
                                textMessage =
                                    "Le créneau horaire choisi est déjà planifié dans un autre établissement.";
                            } else {
                                textMessage =
                                    "Le créneau horaire choisi est déjà planifié";
                            }
                            Swal.fire({
                                title: "Attention!",
                                text: textMessage,
                                icon: "error",
                                timer: 3000,
                            });
                        }
                        for (let i = 0; i < data.length; i++) {
                            handleEditShiftToData(data[i]);
                        }
                        if (error) return false;
                        return true;
                    } else {
                        if (data[0].conflict) {
                            let textMessage = "";
                            if (data[0].fromAnotherSite) {
                                textMessage =
                                    "Le créneau horaire choisi est déjà planifié dans un autre établissement.";
                            } else {
                                textMessage =
                                    "Le créneau horaire choisi est déjà planifié";
                            }
                            Swal.fire({
                                title: "Attention!",
                                text: textMessage,
                                icon: "error",
                                timer: 3000,
                            });
                        }
                        return false;
                    }
                } catch (err: any) {
                    Swal.fire({
                        title: "Attention!",
                        text: err,
                        icon: "error",
                        timer: 3000,
                    });
                    return false;
                }
            } else {
                Swal.fire({
                    title: "Attention!",
                    text: "Pas d'établissements sélectionné",
                    icon: "error",
                    timer: 3000,
                });
                return false;
            }
        },
        [handleEditShiftToData, API],
    );
    const getSiteSettingsEtab = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            try {
                const response = await fetch(
                    API.client.settings.branch.get(siteId),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok === true) {
                    let data = await response.json();
                    dispatch(
                        updatePlanning({
                            siteSettingsEtab: {
                                ...data,
                                pause: Number(data.pause),
                            },
                        }),
                    );
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            // dispatch(updatePlanning({ absenceColors: null }));
        }
    };
    const getPlanningOptionsFromLocalStorage = () => {
        const planningOptionsJson = localStorage.getItem("planningOptions");
        if (planningOptionsJson) {
            const planningOptions = JSON.parse(planningOptionsJson);
            dispatch(
                updatePlanningOptions({
                    hideNotes: planningOptions.hideNotes,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hideAlerts: planningOptions.hideAlerts,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hideAbsences: planningOptions.hideAbsences,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hideDisponibility: planningOptions.hideDisponibility,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hidePlansEmployees: planningOptions.hidePlansEmployees,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hideNoPlansEmployees: planningOptions.hideNoPlansEmployees,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hideTotalTimeDepartment:
                        planningOptions.hideTotalTimeDepartment,
                }),
            );
            dispatch(
                updatePlanningOptions({
                    hideFixedShifts: planningOptions.hideFixedShifts,
                }),
            );
        }
    };
    const setHideNotes = useCallback(
        (hideNotes: boolean) => {
            dispatch(updatePlanningOptions({ hideNotes }));
        },
        [dispatch],
    );

    const setHideAlerts = useCallback(
        (hideAlerts: boolean) => {
            dispatch(updatePlanningOptions({ hideAlerts }));
        },
        [dispatch],
    );

    const setHideAbsences = useCallback(
        (hideAbsences: boolean) => {
            dispatch(updatePlanningOptions({ hideAbsences }));
        },
        [dispatch],
    );

    const setHideDisponibility = useCallback(
        (hideDisponibility: boolean) => {
            dispatch(updatePlanningOptions({ hideDisponibility }));
        },
        [dispatch],
    );

    const setHidePlansEmployees = useCallback(
        (hidePlansEmployees: boolean) => {
            dispatch(updatePlanningOptions({ hidePlansEmployees }));
        },
        [dispatch],
    );

    const setHideNoPlansEmployees = useCallback(
        (hideNoPlansEmployees: boolean) => {
            dispatch(updatePlanningOptions({ hideNoPlansEmployees }));
        },
        [dispatch],
    );

    const setHideTotalTimeDepartment = useCallback(
        (hideTotalTimeDepartment: boolean) => {
            dispatch(updatePlanningOptions({ hideTotalTimeDepartment }));
        },
        [dispatch],
    );

    const setHideFixedShifts = useCallback(
        (hideFixedShifts: boolean) => {
            dispatch(updatePlanningOptions({ hideFixedShifts }));
        },
        [dispatch],
    );
    const getPlannedWeeks = async (weeks: any, depId?: string) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;
            let department = depId;
            if (!depId) {
                department = selectedDepartment?._id || null;
            }

            try {
                const response = await fetch(API.client.planning.plannedWeeks, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                    body: JSON.stringify({
                        department,
                        licenseId,
                        siteId,
                        weeks,
                    }),
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch(updatePlanning({ plannedWeeks: data }));
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const changeDaysServices = (employeesById: any) => {
        let daysServices = Array.from({ length: 7 }, (): any => [...services]);

        if (employeesPlanningData && services?.length > 0) {
            let emps: any = Object.values(employeesById);
            for (let i = 0; i < emps.length; i++) {
                const empPlansPerDay = employeesPlanningData?.employees
                    ? employeesPlanningData?.employees[emps[i]._id]
                    : null;

                if (empPlansPerDay) {
                    for (let j = 0; j < empPlansPerDay.length; j++) {
                        for (
                            let k = 0;
                            k < empPlansPerDay[j].workShares.length;
                            k++
                        ) {
                            let { from, to, type, steaker } =
                                empPlansPerDay[j].workShares[k];
                            const { day } = empPlansPerDay[j];
                            const index =
                                moment(day, "YYYY-MM-DD").isoWeekday() - 1;
                            for (let l = 0; l < services.length; l++) {
                                let fromService = moment
                                    .duration(services[l].from)
                                    .asMinutes();
                                let toService = moment
                                    .duration(services[l].to)
                                    .asMinutes();
                                if (fromService >= toService) {
                                    toService += 1440;
                                }
                                if (from >= to) {
                                    to += 1440;
                                }
                                if (
                                    fromService < to &&
                                    from < toService &&
                                    type === 1
                                ) {
                                    const updatedValue = {
                                        ...daysServices[index][l],
                                        value: [
                                            ...daysServices[index][l].value,
                                            steaker?.name || "",
                                        ],
                                    };
                                    daysServices[index][l] = updatedValue;
                                }
                            }
                        }
                    }
                }
            }
        }
        dispatch(updatePlanning({ daysServices }));
    };
    const changeDepartmentsServices = (employeesById: any) => {
        let departmentsServices: any = {};

        if (employeesPlanningData && services?.length > 0) {
            let emps: any = Object.values(employeesById);

            for (let i = 0; i < departments.length; i++) {
                let depServices = [
                    [...services],
                    [...services],
                    [...services],
                    [...services],
                    [...services],
                    [...services],
                    [...services],
                ];
                for (
                    let counter = 0;
                    counter < departments[i].employees.length;
                    counter++
                ) {
                    const employeeID = departments[i].employees[counter];

                    const employee = employeesById?.[employeeID] ?? undefined;
                    if (
                        !toSearchPlanningEmployees?.[
                            departments[i].employees[counter]
                        ] ||
                        !employee
                    ) {
                        continue;
                    }

                    const empPlansPerDay =
                        (employeesPlanningData?.employees &&
                            employee?._id &&
                            employeesPlanningData?.employees[employee?._id]) ||
                        null;
                    if (empPlansPerDay) {
                        for (let j = 0; j < empPlansPerDay.length; j++) {
                            for (
                                let k = 0;
                                k < empPlansPerDay[j].workShares.length;
                                k++
                            ) {
                                let { from, to, type, steaker } =
                                    empPlansPerDay[j].workShares[k];
                                const { day } = empPlansPerDay[j];
                                const index =
                                    moment(day, "YYYY-MM-DD").isoWeekday() - 1;
                                for (let l = 0; l < services.length; l++) {
                                    let fromService = moment
                                        .duration(services[l].from)
                                        .asMinutes();
                                    let toService = moment
                                        .duration(services[l].to)
                                        .asMinutes();
                                    if (fromService >= toService) {
                                        toService += 1440;
                                    }
                                    if (from >= to) {
                                        to += 1440;
                                    }
                                    if (
                                        fromService < to &&
                                        from < toService &&
                                        type === 1
                                    ) {
                                        const updatedValue = {
                                            ...depServices[index][l],
                                            value: [
                                                ...depServices[index][l].value,
                                                steaker?.name || "",
                                            ],
                                        };
                                        depServices[index][l] = updatedValue;
                                    }
                                }
                            }
                        }
                        departmentsServices[departments[i]._id] = depServices;
                    }
                }
            }
        }
        dispatch(updatePlanning({ departmentsServices }));
    };

    const duplicatePlanning = async (weeks: any, depId?: string) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            const siteId = parsedBranch._id;

            let department = depId;
            if (!depId) {
                department = selectedDepartment?._id || null;
            }
            try {
                dispatch(updatePlanning({ loading: true }));
                const response = await fetch(API.client.planning.duplicate, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                    },
                    body: JSON.stringify({
                        department,
                        licenseId,
                        siteId,
                        week,
                        weeks,
                        year,
                    }),
                });
                if (response.ok) {
                    dispatch(updatePlanning({ loading: false }));
                    return true;
                }
                dispatch(updatePlanning({ loading: false }));
                return false;
            } catch (err) {
                console.log(err);
                dispatch(updatePlanning({ loading: false }));
                return false;
            }
            dispatch(updatePlanning({ loading: false }));
            return false;
        }
    };
    const getDepartments = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            try {
                const response = await fetch(
                    API.client.settings.schedule.getMultiplePlanning(siteId),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok === true) {
                    const data = await response.json();
                    dispatch(
                        updatePlanning({
                            departments: data.filter(
                                (dep: any) => dep.deleted === false,
                            ),
                        }),
                    );
                    return true;
                }
            } catch (err) {
                console.log(err);
            }
        }
        return false;
    };
    const setPlanningEmployees = (planningEmployees: any) => {
        //TOFIX: to be removed
        dispatch(updatePlanning({ planningEmployees }));
    };
    const settoSearchPlanningEmployees = (planningEmployees: any) => {
        dispatch(
            updatePlanning({ toSearchPlanningEmployees: planningEmployees }),
        );
    };

    const selectDayPlanning = (selectedDay: any) => {
        dispatch(updatePlanning({ dayPlanningData: selectedDay }));
    };
    const handleUpdateEmployeesWithoutDepartment = (employeesById: any) => {
        let result: any = {};
        let employeesWithDepartments: string[] = [];
        for (let i = 0; i < departments?.length; i++) {
            employeesWithDepartments = [
                ...employeesWithDepartments,
                ...departments[i].employees,
            ];
        }
        for (const key in employeesById) {
            if (!employeesWithDepartments.includes(key)) {
                result[key] = employeesById[key];
            }
        }
        //order the result
        result = [...Object.values(result)]?.sort(
            (a: any, b: any) => a.fname?.localeCompare(b?.fname || "") || false,
        );
        // const orderMap: { [key: string]: number } = {};
        // planning.customSort?.data?.forEach((order: any) => {
        //     orderMap[order.employee] = order.order;
        // });
        // result = result.sort(
        //     (a: any, b: any) => orderMap[a._id] - orderMap[b._id]
        // );
        dispatch(updatePlanning({ employeesWithoutDepartment: result }));
    };
    const setHoursServices = ({
        departmentsHoursServices,
        hoursServices,
    }: any) => {
        dispatch(updatePlanning({ hoursServices, departmentsHoursServices }));
    };

    const updateCustomSort = async (newCustomSort: any) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            let theCustomSort: any = { ...customSort };
            for (let department of departments) {
                try {
                    const response = await fetch(
                        API.client.planning.updateCustomSort,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem(
                                    "token",
                                )}`,
                            },
                            body: JSON.stringify({
                                siteId,
                                data: [
                                    ...newCustomSort[
                                        department._id
                                    ]?.data?.filter(
                                        (item: any) =>
                                            item.employee && item.name,
                                    ),
                                ],
                                department: department._id,
                                // planningHours: customSort.planningHours,
                            }),
                        },
                    );
                    if (response.ok === true) {
                        const data = await response.json();

                        let newData = [
                            ...data?.data?.map((item: any) => ({
                                ...item,
                                id: `${item.employee}-${item.order}-${item.name}`,
                                detailedInfo:
                                    customSort[department._id]?.data.find(
                                        (dataItem: any) =>
                                            dataItem.employee === item.employee,
                                    )?.detailedInfo || null,
                            })),
                        ];
                        theCustomSort[department._id] = {
                            ...theCustomSort[department._id],
                            data: newData,
                        };
                    }
                } catch (err) {
                    console.log(err);
                }
            }
            if (!departments?.length) {
                try {
                    const response = await fetch(
                        API.client.planning.updateCustomSort,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem(
                                    "token",
                                )}`,
                            },
                            body: JSON.stringify({
                                siteId,
                                data: [
                                    ...newCustomSort?.[
                                        "withoutDepartment"
                                    ]?.data?.filter(
                                        (item: any) =>
                                            item.employee && item.name,
                                    ),
                                ],
                                department: null,
                                // planningHours: customSort.planningHours,
                            }),
                        },
                    );
                    if (response.ok === true) {
                        const data = await response.json();
                        let newData = [
                            ...data?.data?.map((item: any) => ({
                                ...item,
                                id: `${item.employee}-${item.order}-${item.name}`,
                            })),
                        ];
                        theCustomSort["withoutDepartment"] = {
                            ...theCustomSort["withoutDepartment"],
                            data: newData,
                        };
                    }
                } catch (err) {
                    console.log(err);
                }
            }
            dispatch(updatePlanning({ customSort: theCustomSort }));
        }
    };
    const setDraggedShiftType = React.useCallback(
        (draggedShiftType: string) => {
            console.log("PERFORMANCE:\ndraggedShiftType::", draggedShiftType);
            dispatch(updatePlanning({ draggedShiftType }));
        },
        [],
    );
    const handleResetError = () => {
        dispatch(updatePlanning({ error: null }));
    };
    const calculateTime = (emps: any, employeesPlanningData: any) => {
        let totalTime = { allDays: 0, days: Array(7).fill(0) };
        for (let i = 0; i < emps?.length; i++) {
            const empPlansPerDay = employeesPlanningData?.employees
                ? employeesPlanningData?.employees?.[emps[i]._id]
                : null;
            if (empPlansPerDay) {
                for (let j = 0; j < empPlansPerDay.length; j++) {
                    for (
                        let k = 0;
                        k < empPlansPerDay[j].workShares.length;
                        k++
                    ) {
                        const { from, to, pause, day, type, typeAbsence } =
                            empPlansPerDay[j].workShares[k];
                        if (typeAbsence || type === 0) {
                            continue;
                        }

                        const shiftTime =
                            (to >= from ? to - from : to + 1440 - from) - pause;
                        totalTime.allDays += shiftTime;
                        const dayNumber = moment(day, "YYYY-MM-DD").day();
                        const index = dayNumber === 0 ? 6 : dayNumber - 1;
                        totalTime.days[index] += shiftTime;
                    }
                }
            }
        }
        return totalTime;
    };
    const changeTotalTime = (
        employeesData: any,
        employeesPlanningData: any,
        departments: any,
    ) => {
        let totalTimeDepartment: any = {};
        if (!employeesData || !employeesPlanningData || !departments) return {};
        for (let i = 0; i < departments?.length; i++) {
            let emps: any = [];
            for (let empId of departments[i].employees) {
                if (employeesData[empId]) {
                    emps.push(employeesData[empId]);
                }
            }
            totalTimeDepartment[departments[i]._id] = calculateTime(
                emps,
                employeesPlanningData,
            );
        }
        return totalTimeDepartment;
    };
    const getValidatedHolidays = async ({
        from,
        to,
    }: {
        from: string;
        to: string;
    }) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const licenseId = parsedBranch.license;
            try {
                const response = await fetch(
                    API.client.holiday.getvalidatedHolidays,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                        body: JSON.stringify({
                            licenseId,
                            from,
                            to,
                        }),
                    },
                );
                if (response.ok) {
                    let data = await response.json();
                    dispatch(setValidConges(data));
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    function setIsPlanningLoading(loading: boolean) {
        dispatch(updatePlanning({ loading }));
    }
    return {
        planningOptions: options,
        planningLoading: loading,
        planningStartOfWeek: startOfWeek,
        planningEndOfWeek: endOfWeek,
        week,
        year,
        departments,
        loading,
        planningSelectedDepartment: selectedDepartment,
        planningInfoDepartments,
        planningInfo,
        employeesPlanningData,
        services,
        fixedPlans,
        steakers,
        absenceColors,
        loadingVerification: loadingVerification,
        toSearchPlanningEmployees,
        planningCustomSort: customSort,
        planningDisponibility: disponibility,
        sortedEmployeesWithOrder,
        departmentsServices,
        daysServices,
        draggedShiftType,
        hoursServices,
        departmentsHoursServices,
        siteSettingsEtab,
        plannedWeeks,
        validCongesByUser,
        getPlanningInfo,
        getPlanningInfoDepartments,
        getWeeklyPlanning,

        setPlanningProperties,
        getPlanningServices,
        getFixedPlans,
        addWeekPlanningNotes,
        editWeekPlanningNotes,
        deleteWeekPlanningNote,

        printPlanning,
        verifyConvention,
        publishPlanning,
        getCustomSort,
        getEmployeesCustomSort,
        getDisponibility,
        addnewShift,
        handleAddShiftToData,
        getSteakers,
        addNewSteakers,
        getAbsenceColors,
        deletePlanning,
        addNewFixedShift,
        deleteFixedShift,
        editFixedShift,
        getSiteSettingsEtab,
        deleteShift,
        editShift,
        getPlanningOptionsFromLocalStorage,
        setHideNotes,
        setHideAlerts,
        setHideAbsences,
        setHideDisponibility,
        setHidePlansEmployees,
        setHideNoPlansEmployees,
        setHideTotalTimeDepartment,
        setHideFixedShifts,
        getPlannedWeeks,
        changeDaysServices,
        changeDepartmentsServices,
        setPlanningEmployees,
        settoSearchPlanningEmployees,
        duplicatePlanning,
        getDepartments,
        selectDayPlanning,
        handleUpdateEmployeesWithoutDepartment,
        setHoursServices,
        updateCustomSort,
        setDraggedShiftType,
        handleResetError,
        copyShift,
        resetPlanning,
        changeTotalTime,
        calculateTime,

        getValidatedHolidays,
        setIsPlanningLoading,
    };
};
