import * as React from "react";

import "./style.scss";
import logoPNG from "./logo.png";

import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import Button from "../../atoms/Button";

export default function IntegrationCard(props: IntergrationCardProps) {
    const {
        activated,
        logo,
        logoBackgroundColor,
        body,
        bodyColor,
        textButton,
        buttonColor,
        buttonTextColor,
        onClick,
    } = props;
    const logoBgColor = activated
        ? logoBackgroundColor?.[0]
        : logoBackgroundColor?.[1];
    const bodyBgColor = activated ? bodyColor?.[0] : bodyColor?.[1];
    const buttonByColor = activated ? buttonColor?.[0] : buttonColor?.[1];
    const textButtonColor = activated
        ? buttonTextColor?.[0]
        : buttonTextColor?.[1];
    return (
        <div
            className="integration-card-container"
            style={{
                outline: `1px solid #C7CBE2`,
            }}
        >
            <div
                className="integration-logo"
                style={{
                    backgroundColor: logoBgColor,
                }}
            >
                <Picture imgPath={logo} />
            </div>
            <div
                style={{
                    backgroundColor: bodyBgColor,
                }}
                className="integration-body"
            >
                <TextData width="auto" color="#2A346D" id="title">
                    {body?.title}
                </TextData>
                <TextData color="#6F78AB" width="auto" id="description">
                    {body?.description}
                </TextData>
            </div>
            <div
                style={{
                    backgroundColor: logoBgColor,
                    borderTop: "1px solid #C7CBE2",
                    padding: "15px",
                    marginTop: "auto",
                }}
            >
                <Button
                    onClick={onClick}
                    textColor={textButtonColor}
                    backgroundColor={buttonByColor}
                    style={{
                        border: "1px solid #6F78AB",
                    }}
                >
                    {textButton}
                </Button>
            </div>
        </div>
    );
}

interface IntergrationCardProps {
    activated?: boolean;
    logo?: string;
    logoBackgroundColor?: string[];
    body?: {
        title?: string;
        description?: string;
    };
    bodyColor?: string[];
    textButton?: string;
    buttonColor?: string[];
    buttonTextColor?: string[];
    onClick?: () => void;
}

IntegrationCard.defaultProps = {
    activated: true,
    logo: logoPNG,
    logoBackgroundColor: ["#fff", "#f4f9fb"],
    body: {
        title: "Jalia",
        description:
            "Lorem, ipsum dolor amet consectetur adipisicing elit Qui, nemo et? Perferendis beatae nesciunt neque doloremque at adipisci",
    },
    bodyColor: ["#fff", "#fff"],
    textButton: "Activer",
    buttonColor: ["#2A8BAB", "#FFFFFF"],
    buttonTextColor: ["#FFFFFF", "#6F78AB"],
    onClick: () => {},
};
