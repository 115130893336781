import * as React from "react";
import Swal from "sweetalert2";
import "./style.scss";

import Branch from "./Branch";
import Integration from "./Integration";
import Administrative from "./Administrative";
import Schedule from "./Schedule";
import TimeTracker from "./TimeTracker";
import Vacation from "./Vacation";
import VacationBariolo from "./VacationBariolo";

import TextData from "../../components/atoms/TextData";
import DashboardTemplate from "../../components/templates/Dashboard";
import Button from "../../components/atoms/Button";

import TabNavigator from "../../components/molecules/TabNavigationV2";
import { ROUTER } from "../../constants/env";
import { useBranch } from "../../hooks/useBranch";
import { useSchedule } from "../../hooks/useSchedule";

import { BranchProps } from "./Configurations/BranchSettings";
import { ScheduleProps } from "./Configurations/ScheduleSettings";
import { TimeTrackerProps } from "./Configurations/TimeTrackerSettings";
import { MobileProps } from "./Configurations/MobileSettings";
import { NotificationsProps } from "./Configurations/NotificationsSettings";
import { AdministrativeProps } from "./Configurations/AdministrativeSettings";
import { useTimeTracker } from "../../hooks/useTimeTracker";

import AuthFooter from "../../components/organism/AuthFooter";
import Mobile from "./Mobile";
import Notifications from "./Notifications";
import { useMobile } from "../../hooks/useMobile";
import { useAdministrative } from "../../hooks/useAdministrative";
import { VacationProps } from "./Configurations/VacationSettings";
import { VacationBarioloProps } from "./Configurations/VacationBarioloSettings";
import { useCollectiveAgreement } from "../../hooks/useCollectiveAgreement";
import { useNotifications } from "../../hooks/useNotifications";
import { useDPAE } from "../../hooks/useDPAE";
import { useLogin } from "../../hooks/useLogin";

export {
    Branch,
    Integration,
    Schedule,
    TimeTracker,
    Vacation,
    Administrative,
    VacationBariolo,
};

export default function Settings(props: SettingsProps) {
    const {
        branchSettings,
        selectedBranchSettings,
        saveSelectedBranch,
        getBranchSettings,
        updateBranchSettings,
    } = useBranch();
    const { auth } = useLogin();

    const { updateScheduleSettings } = useSchedule();
    const { updateTimeTracker } = useTimeTracker();
    const { updateMobile } = useMobile();
    const { updateNotificationsSettings } = useNotifications();
    const { updateAdministrative, administrative } = useAdministrative();
    const { updateSelectedCollectiveAgreement } = useCollectiveAgreement();
    const { updateDPAEinformations } = useDPAE();
    const isCommingSoon = () => {
        return false;
        // if (selectedBranchSettings._id) {
        //     return false;
        // } else {
        //     return true;
        // }
    };
    const SubmitPlanningSettings = () => {
        updateScheduleSettings();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const SubmitTimeTrackerSettings = () => {
        updateTimeTracker();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const SubmitMobileSettings = () => {
        updateMobile();
        updateBranchSettings();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const SubmitNotificationsSettings = () => {
        updateNotificationsSettings();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const SubmitAdministrativeSettings = async () => {
        let response: any;
        if (administrative.enableDPAE) {
            response = await updateDPAEinformations();
            if (response.error) {
                if (response?.htmlMsg) {
                    Swal.fire({
                        icon: "error",
                        title: "Échec de la mise à jour des paramètres DPAE",
                        html: `<div style="display:flex;flex-direction:column;align-items:start;margin-left: 20%;">
                        ${response.htmlMsg.join("")}
                        </div>`,
                    });
                } else {
                    Swal.fire({
                        title: "Échec de la mise à jour des paramètres1",
                        color: "#2A8BAB",
                        text: "Veuillez réessayer plus tard",
                        icon: "error",
                        confirmButtonText: "D'accord",
                        confirmButtonColor: "#2A8BAB",
                    });
                }
                return;
            }
        }
        response = await updateAdministrative();
        if (response.error) {
            Swal.fire({
                title: "Échec de la mise à jour des paramètres2",
                color: "#2A8BAB",
                text: "Veuillez réessayer plus tard",
                icon: "error",
                confirmButtonText: "D'accord",
                confirmButtonColor: "#2A8BAB",
            });
            return;
        }
        response = updateSelectedCollectiveAgreement();
        if (response.error) {
            Swal.fire({
                title: "Échec de la mise à jour des paramètres3",
                color: "#2A8BAB",
                text: "Veuillez réessayer plus tard",
                icon: "error",
                confirmButtonText: "D'accord",
                confirmButtonColor: "#2A8BAB",
            });
            return;
        }
        Swal.fire({
            title: "Mise à jour réussie",
            color: "#2A8BAB",
            text: "Vos paramètres ont été modifiés avec succès !",
            icon: "success",
            confirmButtonText: "D'accord",
            confirmButtonColor: "#2A8BAB",
        });
    };

    const { children } = props;

    const { Settings } = useBranch();
    const allowedAccessPages = React.useMemo(() => {
        let allowedPages: string[] = [
            "Planning",
            "Pointeuse",
            "Employés",
            "Rapports",
        ];
        let currentSite = selectedBranchSettings?._id ?? Settings.site;
        if (currentSite) {
            const user = sessionStorage.getItem("user");
            if (user) {
                let manage = JSON.parse(user).manage;
                for (let element of manage) {
                    if (element.site === currentSite) {
                        allowedPages = [...element.roles];
                        break;
                    }
                }
            }
            return allowedPages;
        } else {
            return [];
        }
    }, [selectedBranchSettings?._id, Settings.site]);

    const [isBariolo, setIsBariolo] = React.useState(false);
    React.useEffect(() => {
        if (
            branchSettings?._id !== null &&
            (auth?.user?.email == "sbelhadj@bariolojuices.com" ||
                auth?.user?.email == "sbenkhaled@bariolojuices.com" ||
                auth?.user?.email == "contact.esperoo@gmail.com")
        ) {
            setIsBariolo(true);
        } else {
            setIsBariolo(false);
        }
    }, [branchSettings?._id, auth?.user]);

    return (
        <div className="w-full">
            <div className="settings-container">
                <div className="settings-title">
                    <TextData
                        size="34px"
                        fontWeight="bold"
                        color="var(--v-700)"
                    >
                        Paramètres
                    </TextData>
                </div>
                <div
                    className="setting-body"
                    style={{
                        position: "relative",
                    }}
                >
                    <TabNavigator
                        tabs={
                            auth?.user?.license?.planningOn &&
                            allowedAccessPages.includes("Planning")
                                ? [
                                      {
                                          title: " Etablissement",
                                          component: (
                                              <Branch
                                                  commingSoon={isCommingSoon()}
                                                  selectedBranch={
                                                      selectedBranchSettings?.name
                                                          ? selectedBranchSettings?.name
                                                          : false
                                                  }
                                                  settings={BranchProps()}
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.BRANCH,
                                      },
                                      {
                                          title: " Planning",
                                          component: (
                                              <Schedule
                                                  commingSoon={isCommingSoon()}
                                                  settings={ScheduleProps()}
                                                  handleSubmitButton={
                                                      SubmitPlanningSettings
                                                  }
                                                  submitButtonText="Modifier"
                                                  cancelButtonText="Annuler"
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.SCHEDULE,
                                      },
                                      {
                                          title: " Pointeuse",
                                          component: (
                                              <TimeTracker
                                                  settings={TimeTrackerProps()}
                                                  handleSubmitButton={
                                                      SubmitTimeTrackerSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.TIME_TRACKER,
                                      },
                                      {
                                          title: "Mobile",
                                          component: (
                                              <Mobile
                                                  commingSoon={false}
                                                  settings={MobileProps()}
                                                  handleSubmitButton={
                                                      SubmitMobileSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.MOBILE,
                                      },
                                      {
                                          title: "Notifications",
                                          component: (
                                              <Notifications
                                                  commingSoon={false}
                                                  settings={NotificationsProps()}
                                                  handleSubmitButton={
                                                      SubmitNotificationsSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.NOTIFICATIONS,
                                      },
                                      //   {
                                      //       title: "Congés",
                                      //       component: (
                                      //           <Vacation
                                      //               commingSoon={false}
                                      //               settings={VacationProps()}
                                      //           />
                                      //       ),
                                      //       link: ROUTER.SETTINGS.VACATION,
                                      //   },

                                      {
                                          title: "Congé",
                                          component: (
                                              <VacationBariolo
                                                  commingSoon={false}
                                                  settings={VacationBarioloProps()}
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.VACATION,
                                          disable: isBariolo,
                                      },
                                      {
                                          title: " Intégration",
                                          component: <Integration />,
                                          link: ROUTER.SETTINGS.INTEGRATION,
                                      },
                                      {
                                          title: " Formalités Administratives",
                                          component: (
                                              <Administrative
                                                  commingSoon={false}
                                                  settings={AdministrativeProps()}
                                                  handleSubmitButton={
                                                      SubmitAdministrativeSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.ADMINISTRATIVE,
                                      },
                                  ]
                                : [
                                      {
                                          title: " Etablissement",
                                          component: (
                                              <Branch
                                                  commingSoon={isCommingSoon()}
                                                  selectedBranch={
                                                      selectedBranchSettings?.name
                                                          ? selectedBranchSettings?.name
                                                          : false
                                                  }
                                                  settings={BranchProps()}
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.BRANCH,
                                      },

                                      {
                                          title: " Pointeuse",
                                          component: (
                                              <TimeTracker
                                                  settings={TimeTrackerProps()}
                                                  handleSubmitButton={
                                                      SubmitTimeTrackerSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.TIME_TRACKER,
                                      },
                                      {
                                          title: "Mobile",
                                          component: (
                                              <Mobile
                                                  commingSoon={false}
                                                  settings={MobileProps()}
                                                  handleSubmitButton={
                                                      SubmitMobileSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.MOBILE,
                                      },
                                      {
                                          title: "Notifications",
                                          component: (
                                              <Notifications
                                                  commingSoon={false}
                                                  settings={NotificationsProps()}
                                                  handleSubmitButton={
                                                      SubmitNotificationsSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.NOTIFICATIONS,
                                      },
                                      {
                                          title: "Congé",
                                          component: (
                                              <VacationBariolo
                                                  commingSoon={false}
                                                  settings={VacationBarioloProps()}
                                              />
                                          ),
                                          disable: isBariolo,
                                          link: ROUTER.SETTINGS.VACATION,
                                      },
                                      {
                                          title: " Intégration",
                                          component: <Integration />,
                                          link: ROUTER.SETTINGS.INTEGRATION,
                                      },
                                      {
                                          title: " Formalités Administratives",
                                          component: (
                                              <Administrative
                                                  commingSoon={false}
                                                  settings={AdministrativeProps()}
                                                  handleSubmitButton={
                                                      SubmitAdministrativeSettings
                                                  }
                                              />
                                          ),
                                          link: ROUTER.SETTINGS.ADMINISTRATIVE,
                                      },
                                  ]
                        }
                    />
                </div>
            </div>
        </div>
    );
}

interface SettingsProps {
    children?: React.ReactNode;
}
Settings.defaultProps = {
    children: <Branch />,
};
