import React, { useCallback, useState } from "react";
import { useEmployees } from "../../../hooks/useEmployees";
import NoDocumentsFound from "../../../components/templates/EmployeesEdit/NoDoumentsFound";
import ButtonZ from "../../../components/atoms/ZButton";
import Button from "../../../components/atoms/Button";
import { AddSquare } from "../../../Icons";
import AddDocumentCard from "../../../components/organism/AddDocumentCard";
import DeleteDocumentDialog from "./DeleteDocumentDialog";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { ReactComponent as TrashSVG } from "./trash.svg";
import { ReactComponent as EditSVG } from "./edit.svg";
import { ReactComponent as VisibleSVG } from "./visible.svg";
import TextData from "../../../components/atoms/TextData";
import { ROUTER } from "../../../constants/env";
import Loading from "../../../components/atoms/Loading";
import Swal from "sweetalert2";
import Ticket from "../../../components/atoms/Ticket";
import { employeeRoleColor, getColorRgba } from "../../../_helpers/Functions";
import { useDPAE } from "../../../hooks/useDPAE";
import ZAlert from "../../../components/atoms/ZAlert";
import { CircleInfos } from "../../../Icons";
import Popover from "../../../components/molecules/Popover";

import {
    ChevronDownIcon,
    ChevronRightIcon,
    EditIcon,
    TrashIcon,
    UploadCloudIcon,
} from "lucide-react";
import AddFolderCard from "../../../components/organism/AddFolderCard/AddFolderCard";

export default function NotesPage() {
    const intl = useIntl();
    const {
        getEmployeeDocuments,
        employeeForEdit,
        documents,
        listLoading,
        dropExistingDocument,
        removeFolder,
    } = useEmployees();
    const { isDepositLoading } = useDPAE();

    const [folderToEdit, setFolderToEdit] = useState<any>(null);
    const [documentToEdit, setDocumentToEdit] = useState<any>(null);
    const [documentForDelete, setDocumentForDelete] = React.useState();

    const [openAddDocumentCard, setOpenAddDocumentCard] = React.useState(false);
    const [openAddFolderCard, setOpenAddFolderCard] = React.useState(false);

    React.useEffect(() => {
        if (employeeForEdit) {
            getEmployeeDocuments({ id: employeeForEdit._id });
        }
    }, [employeeForEdit, isDepositLoading]);

    const AddDocumentButton = (
        <>
            <ButtonZ
                containerClasses="bg-t-500-op-1 hover:bg-t-500-op-3 text-t-500"
                title={intl.formatMessage({
                    id: "EMPLOYEE.EDIT.ADD_FOLDER",
                })}
                leftComponent={<AddSquare />}
                onClick={() => setOpenAddFolderCard(true)}
            />

            <AddFolderCard
                display={openAddFolderCard}
                onClose={() => {
                    setFolderToEdit(null);
                    setOpenAddFolderCard(false);
                }}
                folderToEdit={folderToEdit}
            />
        </>
    );
    const DocumentsList = () => {
        let files = documents?.files ?? [];
        const [isOpenList, setIsOpenList] = React.useState<Array<boolean>>([]);

        React.useEffect(() => {
            if (documents && documents.folders) {
                setIsOpenList((prev: any) => {
                    return [
                        true,
                        ...documents.folders.map((element: any) => false),
                    ];
                });
            }
        }, [documents]);
        const toggleIsOpenList = (index: number) => {
            setIsOpenList((prev: any) => {
                return prev.map((isOpen: any, i: number) => {
                    if (i === index) {
                        return !isOpen;
                    } else return isOpen;
                });
            });
        };
        if (listLoading) {
            return (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "50vh",
                    }}
                >
                    <Loading />
                </div>
            );
        }

        const content = [
            {
                tag: null,
                id: undefined,
                files: [],
                folder: null,
            },
            ...(documents.folders?.map((folder: any) => {
                return {
                    tag: folder.name,
                    id: folder._id,
                    files: [],
                    folder,
                };
            }) ?? []),
        ].filter((item) => item);
        for (const file of files) {
            const element = content.find(
                (item: any) => item.id === file.folder?._id,
            );
            if (element) {
                element.files.push(file);
            }
        }
        const handleEditDocument = (documentID: string) => {
            const existedDoc = documents.files.find(
                (doc: any) => doc._id === documentID,
            );
            setDocumentToEdit(existedDoc);
            setOpenAddDocumentCard(true);
        };
        const handleDropDocument = (documentID: string) => {
            const existedDoc = documents.files.find(
                (doc: any) => doc._id === documentID,
            );
            Swal.fire({
                title: "Confirmation de suppression",
                html: `  
                Confirmez-vous la suppression de ce fichier ?<br />  
                <i>Attention, cette action est définitive.</i>  
            `,

                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Annuler",
                confirmButtonText: "Supprimer",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await dropExistingDocument(existedDoc._id);
                    await getEmployeeDocuments({ id: employeeForEdit._id });
                    Swal.fire({
                        title: "Suppression confirmée",
                        text: "Votre fichier a bien été supprimé.",
                        icon: "success",
                        confirmButtonText: "Fermer",
                    });
                }
            });
        };
        const handleSizeView = (fileSize: number) => {
            const sizeInMB = (fileSize / 1024).toFixed(2);
            if (parseFloat(sizeInMB) < 0.02) {
                return `${fileSize.toFixed(2)} KB`;
            }
            return `${(fileSize / 1024).toFixed(2)} MB`;
        };

        const getDeleteTextForFolder = (folder: any) => {
            const files = documents.files.filter(
                (f: any) => f.folder?._id === folder?._id,
            );
            const isTemplate = folder?.employee === null;
            return `Confirmez-vous la suppression de ce dossier ? ${files.length > 0 ? `tous les fichiers de ce répertoire seront supprimés \n  <strong>(${files.length} fichier${files.length > 1 ? "s" : ""} à supprimer) </strong>` : ""}<br />  
            <i>Attention, cette action est définitive.</i> `;
        };

        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        gap: 10,
                    }}
                >
                    <Button
                        backgroundColor="#2a8bab1a"
                        textColor="#2a8bab"
                        width="auto"
                        padding="1rem 0.75rem"
                        style={{
                            marginBottom: "7px",
                            fontSize: "15px",
                            borderRadius: "0.5rem",
                            gap: "5px",
                            opacity: content?.length <= 1 ? "0.2" : "",
                            cursor: content?.length <= 1 ? "not-allowed" : "",
                        }}
                        onClick={() => {
                            if (content?.length > 1) {
                                setDocumentToEdit(null);
                                setOpenAddDocumentCard(true);
                            }
                        }}
                    >
                        <UploadCloudIcon />
                        Ajouter un fichier
                    </Button>

                    <Button
                        backgroundColor="#2a8bab1a"
                        textColor="#2a8bab"
                        width="auto"
                        padding="1rem 0.75rem"
                        style={{
                            marginBottom: "7px",
                            fontSize: "15px",
                            borderRadius: "0.5rem",
                            gap: "5px",
                        }}
                        onClick={() => {
                            setDocumentToEdit(null);
                            setOpenAddFolderCard(true);
                        }}
                    >
                        <AddSquare />
                        Créer un nouveau dossier
                    </Button>

                    <AddDocumentCard
                        display={openAddDocumentCard}
                        onClose={() => {
                            setDocumentToEdit(null);
                            setOpenAddDocumentCard(false);
                        }}
                        documentToEdit={documentToEdit}
                    />
                    <AddFolderCard
                        display={openAddFolderCard}
                        onClose={() => {
                            setOpenAddFolderCard(false);
                            setFolderToEdit(null);
                        }}
                        folderToEdit={folderToEdit}
                    />
                </div>
                {content.map((fileGroup: any, index: number) => {
                    const managerColor = employeeRoleColor("manager");
                    const employeeColor = employeeRoleColor("employee");
                    return (
                        <>
                            <div
                                style={{
                                    backgroundColor: "#EDEFF5",
                                    borderRadius: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "12px 15px",
                                    justifyContent: "space-between",
                                    marginBottom: "7px",
                                    cursor: "pointer",
                                }}
                                onClick={() => toggleIsOpenList(index)}
                            >
                                {isOpenList?.[index] ? (
                                    <ChevronDownIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                                {fileGroup.tag === null ? (
                                    <TextData
                                        variant="div"
                                        size="16px"
                                        color="#1A7290"
                                        fontWeight="700"
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            🗂️ Fichiers déposés par le
                                            collaborateur{" "}
                                            {fileGroup.files?.length > 0
                                                ? `(${fileGroup.files.length} fichier${fileGroup.files.length > 1 ? "s" : ""})`
                                                : ""}
                                        </div>
                                        <Popover
                                            width={"800px"}
                                            left={""}
                                            right={"-20px"}
                                            text={`  Le dossier (Fichiers déposés par le collaborateur) est conçu
                                                                    pour centraliser automatiquement les fichiers transmis par
                                                                    vos employés via l'application mobile Esperoo. Dès qu'un
                                                                    fichier est déposé par un employé, vous recevez une
                                                                    notification, et le document apparaît directement dans ce
                                                                    dossier. Vous avez également la possibilité de transférer ce
                                                                    fichier dans un autre dossier si vous le souhaitez, pour une
                                                                        organisation plus personnalisée.`}
                                        />
                                    </TextData>
                                ) : (
                                    <>
                                        <TextData
                                            variant="div"
                                            size="16px"
                                            color="#2A346D"
                                            fontWeight="700"
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 2,
                                            }}
                                        >
                                            {fileGroup.tag}{" "}
                                            {fileGroup.files?.length > 0
                                                ? `(${fileGroup.files.length} fichier${fileGroup.files.length > 1 ? "s" : ""})`
                                                : ""}
                                        </TextData>
                                        <div
                                            className={
                                                "flex flex-1 justify-center gap-2"
                                            }
                                        >
                                            {fileGroup.folder?.permissions.indexOf(
                                                "forEmployees",
                                            ) > -1 && (
                                                <Ticket
                                                    textColor={employeeColor}
                                                    backgroundColor={getColorRgba(
                                                        employeeColor,
                                                    )}
                                                    border={`1px solid ${employeeColor}`}
                                                    text="Employés"
                                                    showClose={false}
                                                    showEdit={false}
                                                />
                                            )}
                                            {fileGroup.folder?.permissions.indexOf(
                                                "forManagers",
                                            ) > -1 && (
                                                <Ticket
                                                    textColor={managerColor}
                                                    backgroundColor={getColorRgba(
                                                        managerColor,
                                                    )}
                                                    border={`1px solid ${managerColor}`}
                                                    text="Managers"
                                                    showClose={false}
                                                    showEdit={false}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                                {fileGroup.tag && (
                                    <div
                                        className={
                                            "flex row gap-3 flex-1 justify-end"
                                        }
                                    >
                                        <div
                                            className={
                                                "flex row gap-1 items-center"
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setFolderToEdit(
                                                    fileGroup.folder,
                                                );
                                                setOpenAddFolderCard(true);
                                            }}
                                        >
                                            <EditIcon />
                                            <span className="font-light text-lg">
                                                Modifier
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                "flex row gap-1 items-center"
                                            }
                                            // Swal.fire({
                                            //     title: "Confirmation de suppression",
                                            //     html: `
                                            //     Confirmez-vous la suppression de ce fichier <strong>${existedDoc.fileName}</strong> ?<br />
                                            //     <i>Attention, cette action est définitive.</i>
                                            // `,

                                            //     icon: "warning",
                                            //     showCancelButton: true,
                                            //     confirmButtonColor: "#3085d6",
                                            //     cancelButtonColor: "#d33",
                                            //     cancelButtonText: "Annuler",
                                            //     confirmButtonText: "Supprimer",
                                            // }).then(async (result) => {
                                            //     if (result.isConfirmed) {
                                            //         await dropExistingDocument(existedDoc._id);
                                            //         await getEmployeeDocuments({ id: employeeForEdit._id });
                                            //         Swal.fire({
                                            //             title: "Suppression confirmée",
                                            //             text: "Votre fichier a bien été supprimé.",
                                            //             icon: "success",
                                            //             confirmButtonText: "Fermer",
                                            //         });
                                            //     }
                                            // });
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                // handle delete icon
                                                Swal.fire({
                                                    title: "Confirmation de suppression",
                                                    html: getDeleteTextForFolder(
                                                        fileGroup.folder,
                                                    ),
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    showDenyButton:
                                                        fileGroup.folder
                                                            ?.employee === null,
                                                    showLoaderOnConfirm: true,
                                                    showLoaderOnDeny: true,
                                                    confirmButtonColor:
                                                        "#3085d6",
                                                    denyButtonColor: "#facea8",
                                                    cancelButtonColor: "#d33",
                                                    denyButtonText:
                                                        "Supprimer pour tous",
                                                    cancelButtonText: "Annuler",
                                                    confirmButtonText:
                                                        fileGroup.folder
                                                            ?.employee === null
                                                            ? `Supprimer seulement pour ${employeeForEdit.fname} ${employeeForEdit.lname}`
                                                            : "Supprimer",
                                                    preDeny: async () => {
                                                        const res =
                                                            await removeFolder(
                                                                fileGroup.id,
                                                                undefined,
                                                            );
                                                        return res;
                                                    },
                                                    preConfirm: async () => {
                                                        const res =
                                                            await removeFolder(
                                                                fileGroup.id,
                                                                employeeForEdit._id,
                                                            );
                                                        return res;
                                                    },
                                                    allowOutsideClick: () =>
                                                        !Swal.isLoading(),
                                                }).then(async (result) => {
                                                    if (result.isConfirmed) {
                                                        Swal.fire({
                                                            title: "Suppression confirmée",
                                                            text: "Votre dossier a bien été supprimé.",
                                                            icon: "success",
                                                            confirmButtonText:
                                                                "Fermer",
                                                        });
                                                    }
                                                });
                                            }}
                                        >
                                            <TrashIcon />
                                            <span className="font-light text-lg">
                                                Supprimer
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {isOpenList[index] && (
                                <div>
                                    <table style={{ width: "100%" }}>
                                        {fileGroup.files.length === 0 ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginBottom: "7px",
                                                    borderRadius: "7px",
                                                    padding: "5px 7px",
                                                    width: "100%",
                                                    height: "50px",
                                                    backgroundColor: "#edeff5",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Aucun fichier n'est présent dans
                                                ce dossier
                                            </div>
                                        ) : (
                                            <tr>
                                                <td>
                                                    <TextData
                                                        color="#2A346D"
                                                        size="17px"
                                                        width="auto"
                                                        fontWeight="550"
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        Nom de Fichier
                                                    </TextData>
                                                </td>
                                                <td>
                                                    <TextData
                                                        color="#2A346D"
                                                        size="17px"
                                                        fontWeight="550"
                                                        className={[
                                                            "ml-3 w-auto",
                                                        ]}
                                                    >
                                                        Taille
                                                    </TextData>
                                                </td>
                                                <td
                                                    style={{
                                                        paddingLeft: "20px",
                                                    }}
                                                >
                                                    <TextData
                                                        color="#2A346D"
                                                        size="17px"
                                                        width="auto"
                                                        fontWeight="550"
                                                    >
                                                        Actions
                                                    </TextData>
                                                </td>
                                            </tr>
                                        )}
                                        {fileGroup.files.map(
                                            (document: any) => {
                                                return (
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: "40%",
                                                            }}
                                                        >
                                                            <TextData
                                                                color="#6F78AB"
                                                                fontWeight="400"
                                                                size="15px"
                                                                width="auto"
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            >
                                                                {
                                                                    document.fileName
                                                                }
                                                            </TextData>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "30%",
                                                            }}
                                                        >
                                                            <TextData
                                                                color="#6F78AB"
                                                                fontWeight="400"
                                                                size="15px"
                                                                width="auto"
                                                            >
                                                                {handleSizeView(
                                                                    document.fileSize,
                                                                )}
                                                            </TextData>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "30%",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    gap: "10px",
                                                                    marginBottom:
                                                                        "7px",
                                                                }}
                                                            >
                                                                <Button
                                                                    backgroundColor="transparent"
                                                                    textColor="#7a82b1"
                                                                    style={{
                                                                        borderRadius: 5,
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: 2,
                                                                    }}
                                                                    width="fit-content"
                                                                    onClick={() => {
                                                                        const url =
                                                                            ROUTER.STATIC_FILE(
                                                                                document.link,
                                                                            );
                                                                        window.open(
                                                                            url,
                                                                            "_blank",
                                                                        );
                                                                    }}
                                                                >
                                                                    <VisibleSVG
                                                                        color="#7a82b1"
                                                                        title="Consulter document"
                                                                    />
                                                                    <span className="font-light text-lg">
                                                                        Télécharger
                                                                    </span>
                                                                </Button>
                                                                <Button
                                                                    backgroundColor="transparent"
                                                                    textColor="#7a82b1"
                                                                    width="fit-content"
                                                                    style={{
                                                                        borderRadius: 5,
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: 2,
                                                                    }}
                                                                    onClick={() =>
                                                                        handleEditDocument(
                                                                            document._id,
                                                                        )
                                                                    }
                                                                >
                                                                    <EditSVG
                                                                        color="#7a82b1"
                                                                        title="Modifier document"
                                                                    />
                                                                    <span className="font-light text-lg">
                                                                        Modifier
                                                                    </span>
                                                                </Button>
                                                                <Button
                                                                    backgroundColor="transparent"
                                                                    textColor="#7a82b1"
                                                                    width="fit-content"
                                                                    style={{
                                                                        borderRadius: 5,
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: 2,
                                                                    }}
                                                                    onClick={() => {
                                                                        handleDropDocument(
                                                                            document._id,
                                                                        );
                                                                    }}
                                                                >
                                                                    <TrashSVG
                                                                        color="#7a82b1"
                                                                        title="Supprimer document"
                                                                    />
                                                                    <span className="font-light text-lg">
                                                                        Supprimer
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            },
                                        )}
                                    </table>
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
        );
    };
    return (
        <div>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({ id: "EMPLOYEE.EDIT.NOTES" })}
                </title>
            </Helmet>
            {documentForDelete && (
                <DeleteDocumentDialog
                    document={documentForDelete}
                    show={documentForDelete ? true : false}
                    onHide={() => {
                        setDocumentForDelete(undefined);
                    }}
                />
            )}
            {DocumentsList()}
        </div>
    );
}
