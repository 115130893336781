import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportState } from "./types";
import moment from "moment";
import {
    getEmployeeFullReportService,
    getMonthlyWorkHoursService,
    getWeeklyWorkHoursService,
} from "./service";
import {
    getWeeksInMonth,
    getWeeksBetweenDates,
} from "../../../_helpers/Functions";
import { extractNumber } from "../../../_helpers/Functions";
const currentDate = moment();
const initialReportState: ReportState = {
    loading: false,
    error: false,
    success: null,
    year: currentDate.format("YYYY").toString(),
    month: currentDate.format("MM").toString(), //TODO:
    day: currentDate.format("DD").toString(),
    selectedEmployee: null,
    showedEmployee: null,
    employeesData: null,
    weekValidation: null,
    selectedShift: null,
    hideRemainingContractHours: false,
    hideExtraContractHours: false,
    showOnlyCurrentMonthHours: undefined,
    showWorkedHoursEverywhere: undefined,
    showWorkedHours: false,
    showBreaks: false,
    showMeals: false,
    showWorkedHoursInMinutes: false,
    acomptesTotal: null,
    totalBaseSalary: 0,
    totalNetSalary: 0,
    totalSalary: 0,
    totalWorkedDays: 0,
    totalPerDay: null,
    delayDateRange: {
        startDate: "",
        endDate: "",
    },
    workHoursDateRange: {
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().add(1, "month").startOf("month").format("YYYY-MM-DD"),
    },
    delayData: null,
    branchs: [],
    selectedBranchs: [],
    reportRangeType: "month",
    dataLength: {
        payRoll: 0,
        workHours: 0,
    },
};
const reportSlice = createSlice({
    name: "report",
    initialState: initialReportState,
    reducers: {
        resetAllReport: (state) => {
            state = initialReportState;
        },
        setBranchs: (state, action) => {
            state.branchs = action.payload;
        },
        setSelectedBranchs: (state, action) => {
            state.selectedBranchs = action.payload;
        },
        setReportRangeType: (state, action) => {
            if (action.payload !== undefined) {
                state.reportRangeType = action.payload;
            }
        },
        setReport: (state, action) => {
            if (action.payload?.hideExtraContractHours !== undefined)
                state.hideExtraContractHours =
                    action.payload?.hideExtraContractHours;

            if (action.payload?.hideRemainingContractHours !== undefined)
                state.hideRemainingContractHours =
                    action.payload?.hideRemainingContractHours;

            if (action.payload?.showOnlyCurrentMonthHours !== undefined) {
                state.showOnlyCurrentMonthHours =
                    action.payload?.showOnlyCurrentMonthHours;
            }

            if (action.payload?.showWorkedHoursEverywhere !== undefined) {
                state.showWorkedHoursEverywhere =
                    action.payload?.showWorkedHoursEverywhere;
            }

            if (action.payload?.showWorkedHours !== undefined)
                state.showWorkedHours = action.payload?.showWorkedHours;

            if (action.payload?.showMeals !== undefined)
                state.showMeals = action.payload?.showMeals;
            if (action.payload?.showBreaks !== undefined)
                state.showBreaks = action.payload?.showBreaks;
            if (action.payload?.showWorkedHoursInMinutes !== undefined)
                state.showWorkedHoursInMinutes =
                    action.payload?.showWorkedHoursInMinutes;
        },
        setWorkHoursDateRange: (state, action) => {
            if (action.payload?.startDate !== undefined) {
                state.workHoursDateRange.startDate = action.payload?.startDate;
            }
            if (action.payload?.endDate !== undefined) {
                state.workHoursDateRange.endDate = action.payload?.endDate;
            }
        },
        setDataLength: (state, action) => {
            if (action.payload?.workHours !== undefined) {
                state.dataLength.workHours = action.payload?.workHours;
            }
            if (action.payload?.payRoll !== undefined) {
                state.dataLength.payRoll = action.payload?.payRoll;
            }
        },
        updateReport: (state, action) => {
            if (action.payload?.delayData !== undefined) {
                state.delayData = action.payload?.delayData;
            }
            if (action.payload?.startDate !== undefined) {
                state.delayDateRange.startDate = action.payload?.startDate;
            }
            if (action.payload?.endDate !== undefined) {
                state.delayDateRange.endDate = action.payload?.endDate;
            }
            if (action.payload?.year !== undefined) {
                state.year = action.payload?.year;
            }
            if (action.payload?.month !== undefined) {
                state.month = action.payload?.month;
            }
            if (action.payload?.day !== undefined) {
                state.day = action.payload?.day;
            }
            if (action.payload?.showedEmployee !== undefined) {
                if (action.payload?.showedEmployee === null) {
                    state.showedEmployee = null;
                    return;
                }
                const showedEmp = {
                    id: action.payload?.showedEmployee?.id,
                    fname: action.payload?.showedEmployee?.fname,
                    lname: action.payload?.showedEmployee?.lname,
                    picture: action.payload?.showedEmployee?.picture,
                };
                state.showedEmployee = showedEmp;
            }
            if (action.payload?.selectedEmployee !== undefined) {
                if (action.payload?.selectedEmployee === null) {
                    state.selectedEmployee = null;
                    return;
                }
                const selectedEmp = {
                    id: action.payload?.selectedEmployee?.id,
                    fname: action.payload?.selectedEmployee?.fname,
                    lname: action.payload?.selectedEmployee?.lname,
                    picture: action.payload?.selectedEmployee?.picture,
                };
                state.selectedEmployee = selectedEmp;
            }
            if (action.payload?.selectedShift !== undefined) {
                if (state.selectedShift === null) {
                    state.selectedShift = {};
                }
                if (
                    action.payload?.selectedShift?.firstDayOfWeek !== undefined
                ) {
                    state.selectedShift.firstDayOfWeek =
                        action.payload?.selectedShift?.firstDayOfWeek;
                }
                if (
                    action.payload?.selectedShift?.lastDayOfWeek !==
                    undefined &&
                    action.payload?.selectedShift.lastDayOfWeek !== null
                ) {
                    state.selectedShift.lastDayOfWeek =
                        action.payload?.selectedShift?.lastDayOfWeek;
                }
            }
            if (action.payload?.hideRemainingContractHours !== undefined) {
                state.hideRemainingContractHours =
                    action.payload?.hideRemainingContractHours;
            }
            if (action.payload?.hideExtraContractHours !== undefined) {
                state.hideExtraContractHours =
                    action.payload?.hideExtraContractHours;
            }
            if (action.payload?.showOnlyCurrentMonthHours !== undefined) {
                state.showOnlyCurrentMonthHours =
                    action.payload?.showOnlyCurrentMonthHours;
            }
            if (action.payload?.showWorkedHoursEverywhere !== undefined) {
                state.showWorkedHoursEverywhere =
                    action.payload?.showWorkedHoursEverywhere;
            }
            if (action.payload?.showWorkedHours !== undefined) {
                state.showWorkedHours = action.payload?.showWorkedHours;
            }
            if (action.payload?.showBreaks !== undefined) {
                state.showBreaks = action.payload?.showBreaks;
            }
            if (action.payload?.showMeals !== undefined) {
                state.showMeals = action.payload?.showMeals;
            }
            if (action.payload?.showWorkedHoursInMinutes !== undefined) {
                state.showWorkedHoursInMinutes =
                    action.payload?.showWorkedHoursInMinutes;
            }
            localStorage.setItem("report", JSON.stringify(state));
        },
        clearSelectedShift: (state) => {
            state.selectedShift = null;
        },
    },
    extraReducers: (builder) => {
        // CASE 1 : get work hours
        builder.addCase(
            getMonthlyWorkHoursService.pending,
            (state: ReportState) => {
                state.loading = true;
                state.error = false;
                state.success = null;
            },
        );
        builder.addCase(
            getMonthlyWorkHoursService.fulfilled,
            (state: ReportState, action: PayloadAction<any>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                // const weeks = getWeeksInMonth(state.year, state.month);
                const weeks = getWeeksBetweenDates(
                    state.workHoursDateRange.startDate,
                    state.workHoursDateRange.endDate,
                );

                if (
                    action.payload?.employeStats !== undefined &&
                    Object.keys(action.payload?.employeStats).length > 0
                ) {
                    interface AcomptesTotal {
                        [key: string]: number;
                    }
                    state.employeesData = {};
                    let totalBaseSalary = 0;
                    let totalNetSalary = 0;
                    let totalSalary = 0;
                    let totalWorkedDays = 0;
                    let totalAcomptes = [];
                    //TODO: remove this line
                    //const weeks = [6, 9, 10, 11, 12, 13, 14, 15];

                    Object.keys(action.payload?.employeStats).forEach(
                        (key, index) => {
                            const employee = action.payload?.employeStats[key];
                            const contractHours =
                                employee?.info?.contract
                                    ?.tempsDeTravailHebdomadaire;
                            const workedHours = employee?.weeks.map(
                                (week: number, weekIndex: number) => {
                                    console.log(`base ${employee?.newHourBase?.[weekIndex]} - ${week} = restWorkHours ${employee?.newHourBase?.[weekIndex] - week}`)
                                    const workedWeekHours = {
                                        week: weeks[weekIndex],
                                        restWorkHours: week - employee?.newHourBase?.[weekIndex],
                                        totalWorkedHours: week,
                                        extraWorkedHours:
                                            week > contractHours * 60
                                                ? week - contractHours * 60
                                                : 0,
                                        missingWorkedHours:
                                            week < contractHours * 60
                                                ? contractHours * 60 - week
                                                : 0,
                                    };
                                    return workedWeekHours;
                                },
                            );
                            const acomptesList = employee?.acomptes?.map(
                                (acompte: any) => {
                                    return {
                                        name: acompte?.name,
                                        amount: acompte?.total,
                                    };
                                },
                            );
                            totalBaseSalary += extractNumber(
                                employee?.baseSalary,
                            );
                            totalNetSalary += extractNumber(
                                employee?.netSalary,
                            );
                            totalSalary += extractNumber(employee?.salary);
                            totalWorkedDays += employee?.workedDays;
                            const employeeData = {
                                id: key,
                                fname: employee?.info?.fname,
                                lname: employee?.info?.lname,
                                picture: employee?.info?.picture,
                                role: employee?.info?.role,
                                contractHours: contractHours
                                    ? contractHours
                                    : 0,
                                workedHours,
                                totalWorkedHoursOfMonth:
                                    employee?.totale?.duree,

                                extraMonthlyWorkedHours:
                                    employee?.totale?.duree >
                                        employee?.info?.contract
                                            ?.tempsTravailMonthly *
                                        60
                                        ? employee?.totale?.duree -
                                        employee?.info?.contract
                                            ?.tempsTravailMonthly *
                                        60
                                        : 0,
                                missingMonthlyWorkedHours:
                                    employee?.totale?.duree <
                                        employee?.info?.contract
                                            ?.tempsTravailMonthly *
                                        60
                                        ? employee?.info?.contract
                                            ?.tempsTravailMonthly *
                                        60 -
                                        employee?.totale?.duree
                                        : 0,
                                monthlyContractHours:
                                    employee?.info?.contract
                                        ?.tempsTravailMonthly,
                                totalWorkedDays: employee?.workedDays,
                                totalPauses: employee?.totale?.pause,
                                totalPay: employee?.salary,
                                totalMeals: employee?.repas ?? 0,
                                baseSalary: employee?.baseSalary,
                                netSalary: employee?.netSalary,
                                salary: employee?.salary,
                                acomptes: acomptesList,
                            };
                            if (!state.employeesData) {
                                state.employeesData = {};
                            }
                            state.employeesData[key] = employeeData;
                        },
                    );
                    const acomptesTotal: AcomptesTotal = {};
                    state.totalBaseSalary = totalBaseSalary;
                    state.totalNetSalary = totalNetSalary;
                    state.totalSalary = totalSalary;
                    state.totalWorkedDays = totalWorkedDays;
                    Object.values(state.employeesData).forEach((employee) => {
                        employee?.acomptes.forEach((acompte) => {
                            const { name, amount } = acompte;
                            const parsedAmount = parseFloat(amount);
                            if (acomptesTotal[name]) {
                                acomptesTotal[name] += parsedAmount;
                            } else {
                                acomptesTotal[name] = parsedAmount;
                            }
                        });
                    });
                    const result = Object.keys(acomptesTotal).map((name) => {
                        return { name, totalAmount: acomptesTotal[name] };
                    });
                    state.acomptesTotal = result;
                }
                if (
                    action.payload?.validatedWeeks !== undefined &&
                    action.payload?.validatedWeeks.length === weeks.length
                ) {
                    state.weekValidation = [];
                    action.payload?.validatedWeeks.forEach(
                        (weekValidation: any) => {
                            state.weekValidation?.push({
                                weekNumber: weeks[weekValidation.week],
                                isValidated:
                                    weekValidation.validated === true
                                        ? true
                                        : false,
                            });
                        },
                    );
                }
            },
        );
        builder.addCase(
            getMonthlyWorkHoursService.rejected,
            (state: ReportState) => {
                state.error = true;
                state.loading = false;
                state.success = false;
            },
        );
        // CASE 2 : get Employee Full Report Service
        builder.addCase(
            getEmployeeFullReportService.pending,
            (state: ReportState) => {
                state.loading = true;
                state.error = false;
                state.success = null;
            },
        );
        builder.addCase(
            getEmployeeFullReportService.fulfilled,
            (state: ReportState, action: PayloadAction<any>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                //@mohamedSouibgui: I used it to get total workdays and total repas from the previous api "stats/week", but this new api does not send all needed data (including pointeuse shifts and totalPerDay)
                //TODO: tocheck later what is it exactly
                // state.totalPerDay = action.payload?.totalPerDay;
                if (state.selectedShift === null) {
                    state.selectedShift = {};
                }
                state.selectedShift = {
                    ...state.selectedShift,
                    ...action.payload,
                    fullReport: [],
                };
                state.selectedShift.fname = action.payload?.employee?.fname;
                state.selectedShift.lname = action.payload?.employee?.lname;
                state.selectedShift.picture = action.payload?.employee?.picture; //TODO: add url
                state.selectedShift.role = action.payload?.employee?.role;

                state.selectedShift.fullReport =
                    action.payload?.fullReport?.map((dayData: any) => {
                        let branchNames: any[] = [];
                        const data = dayData?.data?.map((action: any) => {
                            if (action?.type === "worked-shift") {
                                const shiftLen = Math.max(
                                    action?.info?.shiftrenumere.arriver.length,
                                    action?.info?.shiftrenumere.quitter.length,
                                );
                                let timingList = [];
                                for (let index = 0; index < shiftLen; index++) {
                                    const arriver =
                                        action?.info?.shiftrenumere.arriver[
                                        index
                                        ];
                                    const quitter =
                                        action?.info?.shiftrenumere.quitter[
                                        index
                                        ];
                                    const timing = {
                                        startTime: arriver?.time || 0,
                                        endTime: quitter?.time || 0,
                                        pause: extractNumber(
                                            action?.info?.shiftrenumere.pause,
                                        ),
                                    };
                                    timingList.push(timing);
                                    branchNames.push(`${action?.info?.establissement?.license?.enseigne || ""} ${action?.info?.establissement?.name || ""}`)
                                }
                                let shiftData = {
                                    date: moment(
                                        action?.info?.shiftDate,
                                    ).format("ddd DD/MM/YY"),
                                    timing: timingList,
                                    duration: extractNumber(
                                        action?.info?.duree,
                                    ),
                                    branchName: branchNames || "",
                                    meals: extractNumber(action?.info?.repas),
                                    comment: action?.info?.comment || "",
                                };

                                action.info = {
                                    ...action.info,
                                    ...shiftData,
                                };
                            }
                            return action;
                        });
                        return {
                            ...dayData,
                            data,
                        };
                    });
            },
        );
        builder.addCase(
            getEmployeeFullReportService.rejected,
            (state: ReportState) => {
                state.error = true;
                state.loading = false;
                state.success = false;
            },
        );
    },
});
export const {
    setReport,
    updateReport,
    clearSelectedShift,
    resetAllReport,
    setWorkHoursDateRange,
    setBranchs,
    setSelectedBranchs,
    setReportRangeType,
    setDataLength,
} = reportSlice.actions;
export default reportSlice.reducer;
