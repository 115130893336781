const { envMode } = require("../../constants/envFile");

let envFile;

switch (envMode) {
    case "production":
        envFile = require("../../constants/production");
        break;
    case "development":
        envFile = require("../../constants/development");
        break;
    case "test":
        envFile = require("../../constants/beta");
        break;
    case "staging":
        envFile = require("../../constants/staging");
        break;
    default:
        envFile = require("../../constants/development");
        break;
}
const { clientURL, StaticURL } = envFile;

// Employees url
export const fetchEmployeesUrl: string = `${clientURL}/users/list`;
export const createEmployeeUrl: string = `${clientURL}/users/create`;
export const updateEmployeeUrl: string = `${clientURL}/users/update`;
export const fetchEmployeeUrl: string = `${clientURL}/users/info`;
export const fetchEmployeeInfosUrl: string = `${clientURL}/human_resources/info`;
export const updateEmployeeInfosUrl: string = `${clientURL}/human_resources/update`;
export const fetchEmployeeDisponibilitiesUrl: string = `${clientURL}/disponibility`;
export const createEmployeeDisponibilitiesUrl: string = `${clientURL}/disponibility/create`;
export const updateEmployeeDisponibilitiesUrl: string = `${clientURL}/disponibility/update`;
export const removeUnavaibilityUrl: string = `${clientURL}/disponibility/delete`;
export const fetchRegisterUrl: string = `${clientURL}/users/registre`;
export const restoreEmployeeUrl: string = `${clientURL}/users/restore`;
export const archiveEmployeeUrl: string = `${clientURL}/users/delete`;
export const fetchContractsUrl: string = `${clientURL}/contract/history`;
export const updateContractUrl: string = `${clientURL}/contract/update`;
export const createContractUrl: string = `${clientURL}/contract/create`;
export const addEmployeeDocumentUrl: string = `${clientURL}/user_files`;
export const fetchEmployeeDocumentsUrl: string = `${clientURL}/user_files`;
export const deleteEmployeeDocumentUrl: string = `${clientURL}/user_files/delete`;
export const fetchEmployeePrimesUrl: string = `${clientURL}/acompte/user`;
export const createEmployeePrimesUrl: string = `${clientURL}/acompte/create`;
export const updateEmployeePrimesUrl: string = `${clientURL}/acompte/update`;
export const deleteEmployeePrimesUrl: string = `${clientURL}/acompte/delete`;
export const listPrimestypesUrl: string = `${clientURL}/acompte-type`;
export const fetchEmployeeHolidaysUrl: string = `${clientURL}/conge/my-list`;
export const updateEmployeePasswordUrl: string = `${clientURL}/users/changePassword`;

// Sites
export const fetchSitesManagementUrl: string = `${clientURL}/sites/manage`;

// Countries
export const fetchCountriesUrl: string = `${clientURL}/static/countries`;

// Pointeuse
export const fetchShiftsListUrl: string = `${clientURL}/shift/list`;
export const fetchShiftsWeekUrl: string = `${clientURL}/shift-week/find`;
export const fetchMonthlyMarkedDatesUrl: string = `${clientURL}/shift-week/monthly`;
export const validateShiftUrl: string = `${clientURL}/shift/validate`;
export const addEmployeeToPointeuseUrl: string = `${clientURL}/shift/add-new-from-app`;
export const updatePointageUrl: string = `${clientURL}/shift/edit`;
export const updateShiftUrl: string = `${clientURL}/shift/update`;
export const validateJourneyUrl: string = `${clientURL}/shift-week/validate/day`;
export const fetchDepartementsUrl: string = `${clientURL}/planning-departments`;
