/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Swal from "sweetalert2";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";

import {
    getListOfManagedBranchs,
    getBranch,
    updateBranch,
    updateSiteSettings,
    fetchBranchSettings,
    fetchPauseRepasConditions,
    addPauseRepasCondition,
    removePauseRepasCondition,
} from "../redux/features/settings/service";

import {
    BranchSettingRequest,
    SelectedBranchPayload,
} from "../redux/features/settings/types";

import {
    // Status
    resetStatus,
    // Site update
    updateSiteSetting,
    // Branch Settings
    updateBranchID,
    updateBranchOpeningHour,
    updateBranchClosingHour,
    updateBranchBreakDuration,
    updateBranchPayed,
    updateBranchDaysForUnavailabilityNotice,
    updateBranchDaysForVacationNotice,
    updateBranchShiftChange,
    updateBranchEnablePayroll,
    updateBranchMultiPlanning,
    updateBranchSalaryCalculationFrequency,
    updateBranchWorkDaysPerMonth,
    updateBranchEnableRegister,
    updateBranchUsePause,
    updateBranchUseRepas,
    updateBranchShowHours,
    updateBranchDaysOff,
    // Selected Settings
    resetSelectedBranch,
    updateSelectedBranchID,
    updateSelectedBranchActive,
    updateSelectedBranchAddress,
    updateSelectedBranchCodeNaf,
    updateSelectedBranchCreatedAt,
    updateSelectedBranchDeleted,
    updateSelectedBranchLastSync,
    updateSelectedBranchLicense,
    updateSelectedBranchName,
    updateSelectedBranchNumeroTva,
    updateSelectedBranchSerialKey,
    updateSelectedBranchSiret,
    updateSelectedBranchUpdatedAt,
    updateSelectedBranchVille,
    updateSelectedBranchZipCode,
} from "../redux/features/settings/settingsSlice";
import { API } from "../constants/env";

export const useBranch = (branchSetting?: SettingBranchState) => {
    const dispatch = useAppDispatch();
    const branchSettings = useAppSelector(
        (state: any) => state?.settings,
        shallowEqual,
    )?.branch;
    const selectedBranchSettings = useAppSelector(
        (state: any) => state?.settings,
        shallowEqual,
    )?.selectedBranch;

    const Settings =
        useAppSelector((state: any) => state?.settings, shallowEqual) || {};

    const loading =
        useAppSelector((state: any) => state?.settings, shallowEqual)
            ?.loading || false;
    const ListOfManagedBranchs = () => {
        dispatch(getListOfManagedBranchs()).then((res) => {});
    };
    const saveSelectedBranch = (
        selectedBranchPayload?: SelectedBranchPayload,
    ) => {
        if (selectedBranchPayload) {
            dispatch(updateSelectedBranchID(selectedBranchPayload._id));
            dispatch(updateSelectedBranchActive(selectedBranchPayload.active));
            dispatch(
                updateSelectedBranchAddress(selectedBranchPayload.address),
            );
            dispatch(
                updateSelectedBranchCodeNaf(selectedBranchPayload.codeNaf),
            );
            dispatch(
                updateSelectedBranchCreatedAt(selectedBranchPayload.created_at),
            );
            dispatch(
                updateSelectedBranchDeleted(selectedBranchPayload.deleted),
            );
            dispatch(
                updateSelectedBranchLastSync(selectedBranchPayload.lastSync),
            );
            dispatch(
                updateSelectedBranchLicense(selectedBranchPayload.license),
            );
            dispatch(updateSelectedBranchName(selectedBranchPayload.name));
            dispatch(
                updateSelectedBranchNumeroTva(selectedBranchPayload.numeroTva),
            );
            dispatch(
                updateSelectedBranchSerialKey(selectedBranchPayload.serialKey),
            );
            dispatch(updateSelectedBranchSiret(selectedBranchPayload.siret));
            dispatch(
                updateSelectedBranchUpdatedAt(selectedBranchPayload.updatedAt),
            );
            dispatch(updateSelectedBranchVille(selectedBranchPayload.ville));
            dispatch(
                updateSelectedBranchZipCode(selectedBranchPayload.zipCode),
            );
        } else {
            dispatch(resetSelectedBranch());
        }
    };
    const getBranchSettings = () => {
        const token = localStorage.getItem("token");
        console.log(" getBranchSettings = ", selectedBranchSettings);
        if (selectedBranchSettings?._id && token) {
            dispatch(
                getBranch({ branchID: selectedBranchSettings?._id, token }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const getSiteSettings = () => {
        const token = localStorage.getItem("token");
        console.log(" getSiteSettings = ", selectedBranchSettings);

        if (selectedBranchSettings?._id && token) {
            dispatch(
                fetchBranchSettings({
                    branchID: selectedBranchSettings?._id,
                    token,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const updateBranchSettings = () => {
        if (branchSettings) {
            setOpeningHour(branchSettings.openingHour);
            setClosingHour(branchSettings.closingHour);
            setBreakDuration(branchSettings.breakDuration);
            setDaysForUnavailabilityNotice(
                branchSettings.daysForUnavailabilityNotice,
            );
            setDaysForVacationNotice(branchSettings.daysForVacationNotice);
            setEnableRegister(branchSettings.enableRegister);
            setEnablePayroll(branchSettings.enablePayroll);
            setSalaryCalculationFrequency(
                branchSettings.salaryCalculationFrequency,
            );
            setWorkDaysPerMonth(branchSettings.workDaysPerMonth);
        }
        const token = localStorage.getItem("token");
        const payload: BranchSettingRequest = {
            token: token,
            branchID: selectedBranchSettings._id,
            body: branchSettings,
        };
        dispatch(updateBranch(payload)).then((res) => {
            if (res.meta.requestStatus == "fulfilled") {
                Swal.fire({ icon: "success" });
                dispatch(resetStatus());
            } else {
                Swal.fire({ icon: "error" });
            }
        });
        dispatch(updateSiteSettings(payload)).then((res) => {
            if (res.meta.requestStatus == "fulfilled") {
                Swal.fire({ icon: "success" });
                dispatch(resetStatus());
            } else {
                Swal.fire({ icon: "error" });
            }
        });
    };

    const setOpeningHour = (openingHour: SettingBranchState["openingHour"]) => {
        dispatch(updateBranchOpeningHour({ openingHour }));
    };
    const setClosingHour = (closingHour: SettingBranchState["closingHour"]) => {
        dispatch(updateBranchClosingHour({ closingHour }));
    };

    const setBreakDuration = (
        breakDuration: SettingBranchState["breakDuration"],
    ) => {
        dispatch(updateBranchBreakDuration({ breakDuration }));
    };

    const setDaysForUnavailabilityNotice = (
        daysForUnavailabilityNotice: SettingBranchState["daysForUnavailabilityNotice"],
    ) => {
        dispatch(
            updateBranchDaysForUnavailabilityNotice({
                daysForUnavailabilityNotice,
            }),
        );
    };

    const setDaysForVacationNotice = (
        daysForVacationNotice: SettingBranchState["daysForVacationNotice"],
    ) => {
        dispatch(updateBranchDaysForVacationNotice({ daysForVacationNotice }));
    };

    const setEnableRegister = (
        enableRegister: SettingBranchState["enableRegister"],
    ) => {
        dispatch(updateBranchEnableRegister({ enableRegister }));
    };
    const setUseRepas = (useRepas: SettingBranchState["useRepas"]) => {
        dispatch(updateBranchUseRepas({ useRepas }));
    };
    const setUsePause = (usePause: SettingBranchState["usePause"]) => {
        dispatch(updateBranchUsePause({ usePause }));
    };
    const setShowHours = (showHours: any) => {
        dispatch(updateBranchShowHours(showHours));
    };
    const setDaysoff = (daysOff: any) => {
        dispatch(updateBranchDaysOff(daysOff));
    };

    const setEnablePayroll = (
        enablePayroll: SettingBranchState["enablePayroll"],
    ) => {
        dispatch(updateBranchEnablePayroll({ enablePayroll }));
    };

    const setSalaryCalculationFrequency = (
        salaryCalculationFrequency: SettingBranchState["salaryCalculationFrequency"],
    ) => {
        dispatch(
            updateBranchSalaryCalculationFrequency({
                salaryCalculationFrequency,
            }),
        );
    };

    const setWorkDaysPerMonth = (
        workDaysPerMonth: SettingBranchState["workDaysPerMonth"],
    ) => {
        dispatch(updateBranchWorkDaysPerMonth({ workDaysPerMonth }));
    };

    const setPauseRepasConditions = () => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings?._id && token) {
            dispatch(
                fetchPauseRepasConditions({
                    branchID: selectedBranchSettings?._id,
                    token,
                }),
            );
        }
    };
    const createPauseRepasCondition = (data: any) => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings?._id && token) {
            return dispatch(
                addPauseRepasCondition({
                    branchID: selectedBranchSettings?._id,
                    token,
                    data,
                }),
            );
        }
    };
    const deletePauseRepasCondition = (conditionID: any) => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings?._id && token) {
            return dispatch(
                removePauseRepasCondition({
                    conditionID,
                    token,
                }),
            );
        }
    };

    return {
        Settings,
        loading,
        branchSettings,
        ListOfManagedBranchs,
        selectedBranchSettings,
        saveSelectedBranch,
        getBranchSettings,
        updateBranchSettings,

        setOpeningHour,
        setClosingHour,
        setBreakDuration,
        setDaysForUnavailabilityNotice,
        setDaysForVacationNotice,
        setEnableRegister,
        setUseRepas,
        setUsePause,
        setShowHours,
        setDaysoff,
        setEnablePayroll,
        setSalaryCalculationFrequency,
        setWorkDaysPerMonth,
        getSiteSettings,

        setPauseRepasConditions,
        createPauseRepasCondition,
        deletePauseRepasCondition,
    };
};

interface SettingBranchState {
    openingHour?: string | null;
    closingHour?: string | null;
    breakDuration?: number | null;
    daysForUnavailabilityNotice?: number | null;
    daysForVacationNotice?: number | null;
    enableRegister?: boolean | null;
    enablePayroll?: boolean | null;
    salaryCalculationFrequency?: "daily" | "hourly" | null;
    workDaysPerMonth?: number | null;
    usePause?: boolean;
    useRepas?: boolean;
}
