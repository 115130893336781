import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";

import "./style.scss";

import Sidebar from "../../molecules/Sidebar";
import TopBar from "../../molecules/TopBar";
import { useBranch } from "../../../hooks/useBranch";
import { useIntl } from "react-intl";

import { useLogin } from "../../../hooks/useLogin";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";
import { API } from "../../../constants/env";
import { useLanguage } from "../../../hooks/useLanguage";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";
import { ROUTER } from "../../../constants/env";
import { useLocation, useParams, useNavigate } from "react-router-dom";
export default function DashboardTemplate(
    props: any /*DashboardTemplateProps*/,
) {
    const intl = useIntl();
    const { children } = props;
    const { ListOfManagedBranchs, saveSelectedBranch, selectedBranchSettings } =
        useBranch();
    const { user, signout } = useLogin();
    React.useEffect(() => {
        ListOfManagedBranchs();
    }, []);

    const [expandedSidebar, setExpandedSidebar] = React.useState(true);
    const [hiddenSidebar, setHiddenSidebar] = React.useState(false);
    React.useEffect(() => {
        const mdSize = 768;
        if (window.innerWidth < mdSize) {
            setHiddenSidebar(true);
        }
        window.addEventListener("resize", () => {
            const width = window.innerWidth;
            if (width < mdSize) {
                setHiddenSidebar(true);
            } else {
                setExpandedSidebar(false);
                setHiddenSidebar(false);
            }
        });
        return () => {
            window.removeEventListener("resize", () => {});
        };
    }, []);

    const toggleExpandeSidebar = () => {
        const width = window.innerWidth;
        const mdSize = 768;
        if (width < mdSize) {
            setExpandedSidebar(true);
            setHiddenSidebar((prev) => !prev);
        } else {
            setExpandedSidebar((prev) => !prev);
        }
    };
    // React.useEffect(() => {
    //     const getMe = async () => {
    //         try {
    //             const response = await fetch(API.client.auth.me, {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     authorization: `Bearer ${localStorage.getItem(
    //                         "token"
    //                     )}`,
    //                 },
    //             });
    //             if (response.ok === true) {
    //                 let data = await response.json();
    //                 let mp: any = {};
    //                 for (let i = 0; i < data?.realLicense?.length; i++) {
    //                     mp[data?.realLicense[i]._id] = data?.realLicense[i];
    //                 }
    //                 setRealLicense(mp);
    //                 console.log(" done");
    //             }
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     };
    //     getMe();
    // }, []);
    const [realLicense, setRealLicense] = React.useState<any>([]);
    const handleBranchs = () => {
        const sessionBranchs = sessionStorage.getItem("branchs");
        const sessionUser = sessionStorage.getItem("user");
        let sessionLicense: any[] = [];
        if (sessionUser) {
            sessionLicense = JSON.parse(sessionUser).realLicense;
        }
        const uniqueLicenses = Array.from(
            new Set(sessionLicense?.map((obj) => JSON.stringify(obj))),
        ).map((item) => JSON.parse(item));
        if (sessionBranchs && sessionBranchs !== "[]") {
            let listOfBranchs = JSON.parse(sessionBranchs);
            if (listOfBranchs.length > 0) {
                if (uniqueLicenses.length > 1) {
                    return [
                        {
                            label: "Tous les établissements",
                            value: "Tous les établissements",
                        },
                        ...listOfBranchs.map((item: any) => {
                            const LicenseEnseigne = uniqueLicenses.find(
                                (license: any) => {
                                    return license._id === item.license;
                                },
                            );
                            let enseigneName = "";
                            if (LicenseEnseigne) {
                                enseigneName = LicenseEnseigne.enseigne;
                            }
                            return {
                                label: `${enseigneName} - ${item.name}`,
                                value: item._id,
                            };
                        }),
                    ];
                } else {
                    return [
                        {
                            label: "Tous les établissements",
                            value: "Tous les établissements",
                        },
                        ...listOfBranchs.map((item: any) => {
                            return { label: item.name, value: item._id };
                        }),
                    ];
                }
            }
            return [
                {
                    label: "Tous les établissements",
                    value: "Tous les établissements",
                },
            ];
        }
        return [
            {
                label: "Tous les établissements",
                value: "Tous les établissements",
            },
        ];
    };
    const [branchs, setBranchs] = React.useState<any>(handleBranchs());

    // React.useEffect(() => {
    //     // because there's at least one items by default in branchs so the loop in the code
    //     // should continue until the branchs array is populated with the remaining elements
    //     if (branchs.length <= 1) {
    //         let newBranchs = handleBranchs();
    //         if (realLicense) {
    //             console.log(" real = ", realLicense);
    //             // newBranchs?.map((item: any) => ({
    //             //     label: realLicense[item.value]?.enseightn + item.label,
    //             //     value: item.value,
    //             // }));
    //         }
    //         setBranchs(newBranchs);
    //     }
    // }, [branchs, realLicense]);
    React.useEffect(() => {
        // because there's at least one items by default in branchs so the loop in the code
        // should continue until the branchs array is populated with the remaining elements
        if (branchs.length <= 1) {
            let newBranchs = handleBranchs();
            // if (realLicense) {
            //     newBranchs = newBranchs?.map((item: any) => {
            //         if (realLicense[item.value]?.enseigne) {
            //             return {
            //                 label:
            //                     realLicense[item.value]?.enseigne +
            //                     " - " +
            //                     item.label,
            //                 value: item.value,
            //             };
            //         } else {
            //             return item;
            //         }
            //     });
            setBranchs(newBranchs);
        }
        // }
    }, [/*realLicense*/ branchs]);

    const [selectedBranch, setSelectedBranch] = React.useState<any>(() => {
        const branchSelected = sessionStorage.getItem("selectedBranch");
        if (branchSelected) {
            const sessionUser = sessionStorage.getItem("user");
            let sessionLicense: any[] = [];
            if (sessionUser) {
                sessionLicense = JSON.parse(sessionUser).realLicense;
            }
            let enseigneName = "";
            const selectedbranchName = JSON.parse(branchSelected)?.name;
            const selectedbranchId = JSON.parse(branchSelected)?._id;
            const selectedBranchLicense = JSON.parse(branchSelected)?.license;
            // saveSelectedBranch(JSON.parse(branchSelected));
            const uniqueLicenses = Array.from(
                new Set(sessionLicense?.map((obj) => JSON.stringify(obj))),
            ).map((item) => JSON.parse(item));
            if (uniqueLicenses.length > 1) {
                const LicenseEnseigne = sessionLicense.find((license: any) => {
                    return license._id === selectedBranchLicense;
                });
                if (LicenseEnseigne) {
                    enseigneName = LicenseEnseigne.enseigne;
                }
                return {
                    label: `${enseigneName} - ${selectedbranchName}`,
                    value: selectedbranchId,
                };
            } else {
                return {
                    label: `${selectedbranchName}`,
                    value: selectedbranchId,
                };
            }
        }
        // saveSelectedBranch();
        return {
            label: "Tous les établissements",
            value: "Tous les établissements",
        };
    });

    const handleItemSelection = (option: any) => {
        const sessionBranchs = sessionStorage.getItem("branchs");
        if (sessionBranchs) {
            let listOfBranchs = JSON.parse(sessionBranchs);
            if (listOfBranchs.length > 0) {
                for (let index = 0; index < listOfBranchs.length; index++) {
                    let item = listOfBranchs[index];
                    if (item._id === option.value) {
                        const sessionUser = sessionStorage.getItem("user");
                        let sessionLicense: any[] = [];
                        if (sessionUser) {
                            sessionLicense =
                                JSON.parse(sessionUser).realLicense;
                        }
                        const uniqueLicenses = Array.from(
                            new Set(
                                sessionLicense?.map((obj) =>
                                    JSON.stringify(obj),
                                ),
                            ),
                        ).map((item) => JSON.parse(item));
                        if (uniqueLicenses.length > 1) {
                            const LicenseEnseigne = sessionLicense.find(
                                (license: any) => {
                                    return license._id === item.license;
                                },
                            );
                            let enseigneName = "";
                            if (LicenseEnseigne) {
                                enseigneName = LicenseEnseigne.enseigne;
                            }
                            sessionStorage.setItem(
                                "selectedBranch",
                                JSON.stringify(item),
                            );
                            saveSelectedBranch(item);
                            setSelectedBranch({
                                label: `${enseigneName} - ${item.name}`,
                                value: item._id,
                            });
                        } else {
                            sessionStorage.setItem(
                                "selectedBranch",
                                JSON.stringify(item),
                            );
                            saveSelectedBranch(item);
                            setSelectedBranch({
                                label: item.name,
                                value: item._id,
                            });
                        }
                        //Sentry
                        Sentry.setTag("Site name", item?.name);
                        Sentry.setTag("Site ID", item?._id);
                        Sentry.setTag("License ID", item?.license);
                        return;
                    }
                }
                sessionStorage.setItem("selectedBranch", "");
                saveSelectedBranch();
            }
        }
    };
    const [expanded, toggleExpand] = React.useState(false);
    // const [isFullScreen, setIsFullScreen] = React.useState(false);
    const { switchFullScreen, isFullScreen } = useLanguage();

    const targetRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        toggleFullScreen(isFullScreen);
    }, [isFullScreen]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                (event.key === "F11" || event.key === "Escape") &&
                document.fullscreenElement
            ) {
                event.preventDefault();
            }
        };
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    const toggleFullScreen = (isFullScreen: boolean) => {
        const target = targetRef.current;
        if (isFullScreen) {
            if (target?.requestFullscreen) {
                target.requestFullscreen();
            }
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
    };
    // if (document.fullscreenElement) {
    //     document.exitFullscreen();
    //     setIsFullScreen(false);

    //     // } else if (document.fullscreenElement) {
    //     //     document.exitFullscreen();
    //     // } else if (target?.requestFullscreen) {
    //     //     target.requestFullscreen();
    // } else if (target?.requestFullscreen) {
    //     target.requestFullscreen();
    //     setIsFullScreen(true);
    // }
    const location = useLocation();

    const links = [
        "/planning",
        "/pointeuse",
        "/employees",
        "/report/worked_hours",
    ];
    const [linkIndex, setLinkIndex] = React.useState(0);
    const [inProfile, setnProfile] = React.useState(false);

    // React.useEffect(() => {
    //     if () {
    //         setnProfile(true);
    //     }
    // }, [wildcardPath]);
    React.useEffect(() => {
        // if (location.pathname === `/employees/${user._id}/edit`) {
        //     setnProfile(true);
        // }
        for (let i = 0; i < links.length; i++) {
            if (links[i] === location.pathname) {
                setLinkIndex(i);
                return;
            }
        }
    }, [location]);

    const navigate = useNavigate();
    const navigateToNextPage = () => {
        if (linkIndex + 1 < links.length) {
            navigate(links[linkIndex + 1]);
        }
    };
    const navigateToPreviousPage = () => {
        if (linkIndex - 1 >= 0) {
            navigate(links[linkIndex - 1]);
        }
    };

    return (
        <div
            className="dashboard-container"
            style={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <div style={{ width: "80px", zIndex: "998" }}>
                <Sidebar
                    expanded={expanded}
                    handleExpand={toggleExpandeSidebar}
                    hidden={hiddenSidebar}
                    toggleExpand={toggleExpand}
                />
            </div>
            <div
                className="body-container"
                style={{
                    width: `calc(100vw - ${expanded ? 250 : 80}px)`,
                }}
            >
                {user && (
                    <TopBar
                        selectElements={branchs}
                        selectedValue={selectedBranch}
                        handleItemSelection={handleItemSelection}
                        handleHamburger={toggleExpandeSidebar}
                        signout={signout}
                        firstName={user.fname}
                        lastName={user.lname}
                        avatarImg={
                            user.picture && user.picture !== ""
                                ? generatePicUrl(user.picture)
                                : undefined
                        }
                        email={user.email}
                        searchPlaceholder={intl.formatMessage({
                            id: "MENU.SEARCH",
                        })}
                    />
                )}
                <div
                    ref={targetRef}
                    style={{
                        height: "calc(100vh - 80px)",
                        overflow: "auto",
                        marginTop: "10px",
                        position: "relative",
                        // padding: "20px",
                        backgroundColor: "#f7f8fa",
                    }}
                >
                    {isFullScreen && (
                        <>
                            {linkIndex > 0 && (
                                <div
                                    className="switch-pages"
                                    // onClick={toggleFullScreen}
                                    style={{
                                        position: "absolute",
                                        height: "100%",
                                        zIndex: "997",
                                        transition: "0.3s ease-in-out",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <ChevronSVG
                                        style={{
                                            cursor: "pointer",
                                            minHeight: "30px",
                                            minWidth: "30px",
                                        }}
                                        height={100}
                                        width={100}
                                        onClick={navigateToPreviousPage}
                                    />
                                </div>
                            )}
                            {linkIndex < links.length - 1 && (
                                <div
                                    className="switch-pages"
                                    // onClick={toggleFullScreen}
                                    style={{
                                        position: "absolute",
                                        height: "100%",
                                        zIndex: "997",
                                        transition: "0.3s ease-in-out",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        right: "0",
                                    }}
                                >
                                    <ChevronSVG
                                        style={{
                                            cursor: "pointer",
                                            minHeight: "30px",
                                            minWidth: "30px",
                                            transform: "rotate(180deg)",
                                        }}
                                        height={100}
                                        width={100}
                                        onClick={navigateToNextPage}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {
                        // wildcardPath === `employees/${user._id}/edit` ||

                        children
                    }
                </div>
            </div>
        </div>
    );

    //     return (
    //         <div>
    //             <Sidebar
    //                 expanded={expandedSidebar}
    //                 handleExpand={toggleExpandeSidebar}
    //                 hidden={hiddenSidebar}
    //             />

    //             <div className='dashboard-container md:ml-[120px]'>
    //                 <div className='content-container'>
    //                     {user && (
    //                         <div
    //                             className='topbar-container'
    //                             style={{ zIndex: "50" }}
    //                         >
    //                             <TopBar
    //                                 selectElements={branchs}
    //                                 selectedValue={selectedBranch}
    //                                 handleItemSelection={handleItemSelection}
    //                                 handleHamburger={toggleExpandeSidebar}
    //                                 signout={signout}
    //                                 firstName={user.fname}
    //                                 lastName={user.lname}
    //                                 avatarImg={
    //                                     user.picture && user.picture !== ""
    //                                         ? generatePicUrl(user.picture)
    //                                         : undefined
    //                                 }
    //                                 email={user.email}
    //                                 searchPlaceholder={intl.formatMessage({
    //                                     id: "MENU.SEARCH",
    //                                 })}
    //                             />
    //                         </div>
    //                     )}
    //                     <div className='body-container'>
    //                         <div className=''>{children}</div>
    //                     </div>
    //                 </div>
    //             </div>
    //             {/*
    //         <AuthFooter
    //           textColor="#6F78AB"
    //           backgroundColor="#fefefe7f"
    //           logo={LogoSVG}
    //           padding="10px 5px"
    //           height="auto"
    //         />*/}
    //         </div>
    //     );
}

interface DashboardTemplateProps {
    children?: React.ReactNode;
}
DashboardTemplate.defaultProps = {
    children: <h1>React Node Not Found</h1>,
};
